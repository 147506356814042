<template>
  <b-container>
    <template v-if="threeYearsCommit.length > 0">
      <b-card v-if="threeYearsCommit?.[0].commitment?.status">
        <b-alert show variant="dark" v-if="threeYearsCommit[0].commitment?.status === 'INACTIVE'">{{ $t("customerNotIn3YC") }}</b-alert>
        <b-alert show variant="secondary" v-if="threeYearsCommit[0].commitment?.status === 'COMMITTED'" v-html="$t('customerIsIn3YCFromDateToDate', {startDate: returnDate(threeYearsCommit[0].commitment?.startDate), endDate: returnDate(threeYearsCommit[0].commitment?.endDate)})"></b-alert>

          <!-- Aktiv -->
        <template v-if="threeYearsCommit[0].commitment?.status === 'COMMITTED'">
          <template v-if="threeYearsCommit[0]?.recommitmentRequest?.status !== 'REQUESTED' && checkIfitEndIn30Days(threeYearsCommit[0]?.commitment.endDate) && threeYearsCommit[0]?.recommitmentRequest?.status !== 'ACCEPTED'" v-html="$t('customerIsIn3YCFromDateToDate', {numberOfDaysTillEnd: checkIfitEndIn30Days(threeYearsCommit[0]?.commitment.endDate)})"> </template>

          <b-progress :value="dateInPercent(threeYearsCommit[0].commitment?.startDate, threeYearsCommit[0].commitment?.endDate)" class="mb-3"></b-progress>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column" v-if="threeYearsCommit[0].commitment?.minimumQuantities[0]?.offerType">
                <small class="text-muted"> {{$t("minimumAmountSubscriptions")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitment?.minimumQuantities[0]?.quantity}}
              </div>
            </div>

            <div class="d-flex flex-column" v-if="threeYearsCommit[0].commitment?.minimumQuantities[1]?.offerType">
                <small class="text-muted"> {{$t("minimumAmountConsumables")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitment?.minimumQuantities[1]?.quantity}}
              </div>
            </div>
          </div>
  <!-- Erneuern -->
          <template v-if="threeYearsCommit[0]?.commitment?.status === 'COMMITTED' && threeYearsCommit[0]?.recommitmentRequest?.status !== 'REQUESTED' && checkIfitEndIn30Days(threeYearsCommit[0]?.commitment.endDate) && threeYearsCommit[0]?.recommitmentRequest?.status !== 'ACCEPTED'">
            <b-button class="mt-3" variant="dark" @click="openModal('recommitmentRequest')">
              <small>{{ $t("extend3YC") }}</small>
            </b-button>
          </template>
          
        </template>
      </b-card>
      <b-card class="mt-2" v-if="threeYearsCommit?.[0].recommitmentRequest?.status">

        <b-alert show variant="warning" v-if="threeYearsCommit[0].recommitmentRequest?.status == 'REQUESTED'">{{ $t("extentionRequested") }}</b-alert>
        <b-alert show variant="danger" v-if="threeYearsCommit[0].recommitmentRequest?.status == 'DECLINED'">{{ $t("customerDeclinedExtention") }}</b-alert>
        <b-alert show variant="success" v-if="threeYearsCommit[0].recommitmentRequest?.status == 'ACCEPTED'" v-html="$t('customerAcceptedExtentionNextTermStartsAt', {startDate: returnDate(threeYearsCommit[0].recommitmentRequest?.startDate)})"></b-alert>

  <!-- Erneuerungsanfrage -->
        <template v-if="threeYearsCommit[0].recommitmentRequest?.status">

          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column" v-if="threeYearsCommit[0].recommitmentRequest?.minimumQuantities[0]?.offerType">
                <small class="text-muted"> {{$t("minimumAmountSubscriptions")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].recommitmentRequest?.minimumQuantities[0]?.quantity}}
              </div>
            </div>

            <div class="d-flex flex-column" v-if="threeYearsCommit[0].recommitmentRequest?.minimumQuantities[1]?.offerType">
                <small class="text-muted"> {{$t("minimumAmountConsumables")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].recommitmentRequest?.minimumQuantities[1]?.quantity}}
              </div>
            </div>
          </div>

        </template>

      </b-card>

      <b-card class="mt-2" v-if="threeYearsCommit?.[0].commitmentRequest?.status && (threeYearsCommit[0].commitmentRequest?.status === 'ACCEPTED' || threeYearsCommit[0].commitmentRequest?.status === 'REQUESTED')">
        <b-alert show variant="warning" v-if="threeYearsCommit[0].commitmentRequest?.status == 'REQUESTED'" v-html="$t('waitingForApproval')"></b-alert>
        <b-alert show variant="success" v-if="threeYearsCommit[0].commitmentRequest?.status == 'ACCEPTED'" v-html="$t('customerAccepted3YC')"></b-alert>
        <b-alert show variant="danger" v-if="threeYearsCommit[0].commitmentRequest?.status == 'DECLINED'" v-html="$t('customerDeclined3YC')"></b-alert>

        <!-- Angefragt -->
        <template v-if="threeYearsCommit[0].commitmentRequest?.status === 'ACCEPTED' || threeYearsCommit[0].commitmentRequest?.status === 'REQUESTED'">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column" v-if="threeYearsCommit[0].commitmentRequest?.minimumQuantities[0]?.offerType">
              <small class="text-muted"> {{$t("minimumAmountSubscriptions")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitmentRequest?.minimumQuantities[0]?.quantity}}
              </div>
            </div>

            <div class="d-flex flex-column" v-if="threeYearsCommit[0].commitmentRequest?.minimumQuantities[1]?.offerType">
                <small class="text-muted"> {{$t("minimumAmountConsumables")}} </small>
              <div>
                <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitmentRequest?.minimumQuantities[1]?.quantity}}
              </div>
            </div>
          </div>
        </template>
      </b-card>
    </template>
    
    <!-- Starten -->
    <div>
      <p class="mb-4 mt-4 text-muted" v-html="$t('customerNotIn3YCSendInvitation')"></p>

      
      <b-button 
        variant="dark" 
        @click="openModal('commitmentRequest')">{{ $t("send3YCInvitation") }}</b-button>
    </div> 



    <b-modal 
      id="3YCQuantities"
      @show="threeYC = {licenses: null, consumables: null}">
      <template #modal-title>
        {{$t('threeYCOption')}} 
          <template v-if="modalVariant == 'recommitmentRequest'">{{ $t("commitmentRequestRenew") }}</template>
          <template v-else>{{ $t("commitmentRequestInvitation") }}</template>
      </template>
      <b-form-group>
        <p v-html="$t('set3YCLevelInfo')" v-if="modalVariant == 'commitmentRequest'"></p>
        <p v-html="$t('renew3YCLevelInfo')" v-else></p>
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("subscriptions") }}</label>
          </b-col>
          <b-col xl="6">
            <b-form-input v-model="threeYC.licenses" type="number" min="0" @keypress="isNumber($event)" ref="licenses"></b-form-input>
            <small class="text-muted" v-show="(threeYC.licenses < 10 && threeYC.licenses != 0) && threeYC.licenses && threeYC.licenses != null">{{ $t("threeYearsCommitMinQuantity") }}</small>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("consumables") }}</label>
          </b-col>
          <b-col xl="6">
            <b-form-input v-model="threeYC.consumables" type="number" min="0" @keypress="isNumber($event)" ref="consumables"></b-form-input>
          </b-col>
        </b-row>

      </b-form-group>
      <template #modal-footer>
        <button v-b-modal.modal-close_visit class="btn btn-secondary btn-sm m-1" @click="$bvModal.hide('3YCQuantities')">{{ $t("abort") }}</button>
        <button v-b-modal.modal-close_visit class="btn btn-primary btn-sm m-1" @click="modalVariant == 'commitmentRequest' ? create3YCommitRequest() : create3YCommitRecommitRequest();">{{ modalVariant == 'commitmentRequest' ? $t("send3YCInvitationNow") : $t("extend3YCNow") }}</button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";


export default {
  name: "BenefitsComponent",
  props: {
    data: Array,
    customerId: String,
    companyProfile: Object,
    discounts: Array,
  },
  data() {
    return {
      threeYearsCommit: this.data.filter(d => d.type === "THREE_YEAR_COMMIT"),
      threeYC: {
        licenses: null,
        consumables: null
      },
      options: {
        threeYC: {
          licenses: [
            {value: null, text: '-',},
            {value: 10, text: '10-49',},
            {value: 50, text: '50-99',},
            {value: 100, text: '100+',}
          ],
          consumables: [
            {value: null, text: '-'},
            {value: 1, text: '1-999'},
            {value: 1000, text: '1000-2499'},
            {value: 2500, text: '2500-4999'},
            {value: 5000, text: '5000-14999'},
            {value: 15000, text: '15000-49999'},
            {value: 50000, text: '50000+'}
          ],
        }
      },
      modalVariant: null,
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async create3YCommitRequest() {

      if (!this.threeYC?.consumables && !this.threeYC?.licenses) {
        this.$store.dispatch("alertError", {
            message: `Es muss mindestens eine Menge gewählt werden. `,
            show: true,
            noFooter: false,
          });
        return
      }

      const setUpMinimumQuantities = {}
      if (this.threeYC?.consumables) setUpMinimumQuantities['consumables'] = this.threeYC.consumables
      if (this.threeYC?.licenses) setUpMinimumQuantities['licenses'] = this.threeYC.licenses

      const request3YC = httpsCallable(functions, "threeYCRequest");
      return await request3YC({
        customerId: this.customerId,
        companyProfile: this.companyProfile,
        minimumQuantities: setUpMinimumQuantities
      })
      .then(() => {
        this.$store.dispatch("alertSuccess", {
          message: "Vielen Dank.<br /><br />Der Kunde wird informiert.",
          show: true,
          noFooter: false,
        })
        this.$bvModal.hide('3YCQuantities')
      })
      .catch( (err) => {
        console.error(err)
        this.$store.dispatch("alertError", {
          message: `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. `,
          show: true,
          noFooter: false,
        });
      });
    },
    async create3YCommitRecommitRequest() {
       if (!this.threeYC?.consumables && !this.threeYC?.licenses) {
        this.$store.dispatch("alertError", {
            message: `Es muss mindestens eine Menge gewählt werden. `,
            show: true,
            noFooter: false,
          });
        return
      }

      const setUpMinimumQuantities = {}
      if (this.threeYC?.consumables) setUpMinimumQuantities['consumables'] = this.threeYC.consumables
      if (this.threeYC?.licenses) setUpMinimumQuantities['licenses'] = this.threeYC.licenses

      const request3YC = httpsCallable(functions, "threeYCRecommitRequest");
      return await request3YC({
        customerId: this.customerId,
        companyProfile: this.companyProfile,
        minimumQuantities: setUpMinimumQuantities
      })
      .then(() => {
        this.$store.dispatch("alertSuccess", {
          message: "Vielen Dank.<br /><br />Der Kunde wird informiert.",
          show: true,
          noFooter: false,
        })
        this.$bvModal.hide('3YCQuantities')
      })
      .catch( (err) => {
        console.error(err)
        this.$store.dispatch("alertError", {
          message: `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. `,
          show: true,
          noFooter: false,
        });
      });
    },
    returnDate(date) {
      let dateToReturn = new Date(date)
      return dateToReturn.toLocaleDateString('de-DE')
    },
    checkIfitEndIn30Days(date, daysToCheck = 30) {
      const endDate = new Date(date);
      if (!(endDate instanceof Date)) {
        return false;
      }
      const today = new Date();
      const thirtyDaysInFuture = new Date();

      thirtyDaysInFuture.setDate(today.getDate() + daysToCheck);

      const arrayToSort = [
        ["today", today],
        ["thirtyDaysInFuture", thirtyDaysInFuture],
        ["endDate", endDate],
      ];

      arrayToSort.sort((a, b) => a[1] - b[1]);

      if (arrayToSort[1][0] == "endDate") {
        const differenceInHoures = endDate.getTime() - today.getTime();
        const differenceInDays = differenceInHoures / (1000 * 3600 * 24);
        if (differenceInDays === daysToCheck) {
          return true;
        } else {
          return Math.round(differenceInDays);
        }
      } else {
        return false;
      }
    },
    dateInPercent(startDate, endDate){
      const start = new Date(startDate)
      const end = new Date(endDate)
      const today = new Date()

      return (Math.round((today - start) / (end - start) * 100 ))
    },
    openModal(state) {
      this.modalVariant = state;
      this.$bvModal.show('3YCQuantities')
    },
    closeModal(state) {
      this.modalVariant = state;
      this.$bvModal.hide('3YCQuantities')
    }

  },
};
</script>
