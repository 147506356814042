export default {
  resellerReferenceId: "Ihre Referenz-ID:",
  teamsLicenses: "Teams Lizenzen",
  teamsLicensesProEditions: `Teams Lizenzen<br />Pro Editions`,
  enterpriseLicenses: "Enterprise Lizenzen",
  enterpriseLicensesProEditions: `Enterprise Lizenzen<br />Edition 4`,
  edition3: `Enterprise Lizenzen<br />Edition 3`,
  orderNumberInputErrorText: "",
  customerRenewalOrderNumberText: `Auf dieser Seite können Sie eine eigene Bestellnummer für die kommende Verlängerung dieses Kunden einstellen.<br />Das Feld wird nach dem Stichtag automatisch geleert.`,
  renewalOrderNumber: `Bestellnummer für Verlängerung`,
  renewalOrderNumberShort: `Renewal-Bestellnr.:`,
  consumableLevel: `Verbrauchsgüter-Rabattstufe`,
  consumables: `Verbrauchsgüter`,
  signTransactions: `Acrobat Services`,
  creditPacksTeams: `Teams Credit Packs`,
  creditPacksEnterprise: `Enterprise Credit Packs`,
  noPermissionToOrder: `Sie haben keine Berechtigung um zu bestellen. Bitte wenden Sie sich an Ihren Administrator um eine Berechtigung zu erhalten.`,
  currentQuantity: "Anzahl aktuell aktiver Lizenzen:",
  userReceivesEmail: "Der Benutzer erhält nun eine E-Mail zur Bestätigung.",
  userAllowedToOrder: "Darf dieser Benutzer bestellen?",
  userIsNotAllowedToOrder: "Sie haben keine Berechtigung zum Bestellen. Bitte wenden Sie sich an Ihren Administrator.",
  yes: "Ja",
  no: "Nein",
  editions: "Editionen",
  createUserEmailHint: "Die E-Mail darf von keinem anderen Benutzer oder dem Hauptkonto verwendet werden.",
  resetPasswordText: "Sie können nun ein neues Passwort für Ihren Adobe Cloud Store Zugang wählen.",
  resendInvitationText: `Der Kunde scheint die Einaldung in den Adobe CMP noch nicht angenommen zu haben.<br />Bei Bedarf können Sie diese erneut senden.`,
  resendInvitation: "Einladung erneut senden",
  missedRenewalText: "Erstellen Sie ganz bequem einen neuen Auftrag, damit Ihr Kunde die Lizenzen weiterhin nutzen kann.",
  missedRenewal: "Renewal verpasst?",
  lateRenewal: "Renewal nachholen",
  createRenewal: "Renewal erstellen",
  cartIsEmpty: "Ihr Warenkorb ist leer",
  newPassword: "Neues Passwort",
  confirmNewPassword: "Neues Passwort wiederholen",
  editLoginCredentialsText: "Auf dieser Seite können Sie Ihre Zugangsdaten ändern.",
  editLoginCredentials: "Zugangsdaten",
  userManagement: "Benutzerverwaltung",
  createUser: "Benutzer hinzufügen",
  editUser: "Benutzer bearbeiten",
  usersText: "Auf dieser Seite können Sie für weitere Mitarbeiter Ihres Unternehmens einen Zugang erstellen.",
  searchByUserName: "Suche nach Name",
  noUsers: "Es sind keine Benutzer vorhanden",
  deleteUserText: "Wollen Sie den Benutzer <strong>{firstName} {lastName}</strong> wirklich löschen?",
  renewalOfferFileName: "acs-renewal-angebot-",
  offerFileName: "acs-angebot-",
  renewalOfferFooterText: "Alle Angebote freibleibend • Irrtümer und Preisänderungen vorbehalten • Die genannten Garantiezeiten entsprechen den jeweiligen Herstellergarantien • Die vereinbarte Lieferung findet unter Vorbehalt der richtigen und rechtzeitigen Selbstbelieferung statt • Es gelten unsere allgemeinen Verkaufs- und Lieferbedingungen",
  offerFrom: "Angebot vom:",
  netFee: "Nettoentgelt",
  grossPrice: "Bruttopreis",
  vat: "Umsatzsteuer (%{taxRate}%)",
  posNo: "Pos. Nr.",
  customerRenewalOfferText: "Auf dieser Seite können Sie ein Angebot für ein anstehendes Renewal als PDF herunterladen.",
  downloadRenewalOffer: "Angebot für Renewal herunterladen",
  news: "Neuigkeiten",
  support: "Support",
  notifications: "Benachrichtigungen",
  notificationsText: "Auf dieser Seite können Sie festlegen, an welche E-Mail-Adresse wir Benachrichtigungen zu anstehenden Renewals und Kaufanfragen senden sollen.",
  imprintLink: "Impressum",
  privacyLink: "Datenschutz",
  termsAndConditionsComlineLink: "AGB {companyName}",
  termsAndConditionsAdobeLink: "AGB Adobe CMP",
  emailLabel: "E-Mail-Adresse",
  emailInputErrorText: "Keine gültige E-Mail-Adresse",
  passwordLabel: "Passwort",
  forgotPasswordLink: "Passwort vergessen",
  signInButton: "Anmelden",
  signUpLink: "Registrieren Sie sich",
  signUpLinkText: "Sie haben noch keinen Zugang?",
  forgotPasswordHeadline: "Passwort zurücksetzen",
  forgotPasswordText:
    "Falls Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen.<br />Geben Sie die E-Mail-Adresse ein, die Sie für die Anmeldung verwendet haben.",
  resetPasswordButton: "Passwort zurücksetzen",
  signInViewLink: "Melden Sie sich an",
  signUpHeadline: "Registrierung",
  signUpText: "Sie sind {companyName}-Kunde?<br />Dann registrieren Sie sich noch heute für den Adobe Cloud Store.",
  accessDataHeadline: "Zugangsdaten",
  accessDataEmailInfo: "Wird später für den Login verwendet",
  confirmEmailLabel: "E-Mail-Adresse wiederholen",
  confirmEmailInputErrorText: "Die E-Mail-Adressen sind nicht identisch",
  companyData: "Firmendaten",
  customerNumber: "Kundennummer", 
  customerNumberIsNotRequired: "Kundennummer in Ihrem System. Optionale Angabe.",
  comlineCustomernumberLabel: "{companyName} Kundennummer",
  comlineCustomernumberInputErrorText: "Keine gültige {companyName} Kundennummer",
  companyLabel: "Firma",
  afterwardsNotChangeableInfo: "Nachträglich nicht mehr änderbar",
  notChangeableInfo: "Nicht änderbar",
  companyInputErrorText: "Der Firmenname ist ungültig",
  addressLine1Label: "Straße und Hausnummer",
  addressLine2Label: "Adresszusatz",
  addressLine2Info: "Optional",
  postalCodeLabel: "PLZ",
  postalCodeInputErrorText: "Die PLZ ist ungültig",
  cityLabel: "Ort",
  countryLabel: "Land",
  phoneNumberLabel: "Telefonnummer",
  prefferredLanguageLabel: "Bevorzugte Sprache",
  companyLogo: "Firmenlogo",
  companyLogoUploadInformation: "Format: .png, .jpg, .gif | Bilddimensionen: 512px x 512px | Bildgröße: (max) 5mb",
  companyLogoUploadInfo: "Ihr Firmenlogo für ihre Angebote.",
  selectImage: "Bild wählen...",
  companyLogoUploadErrorDimensions: "Ihre Bilddimensionen sind nicht 512px x 512px!",
  companyLogoUploadErrorSize: "Ihr Bild ist zu groß!",
  companyLogoUploadErrorType: "Bitte verwenden Sie ein Bild!",
  contactHeadline: "Ansprechpartner",
  firstNameLabel: "Vorname",
  firstNameInputErrorText: "Der Vorname ist ungültig",
  lastNameLabel: "Nachname",
  lastNameInputErrorText: "Der Nachname ist ungültig",
  legalHeadline: "Rechtliches",
  termsAndConditionsLabel: "Ich akzeptiere die",
  signInLinkText: "Sie haben bereits einen Zugang?",
  signUpButton: "Registrieren",
  comlineContactsHeadline: "Ansprechpartner",
  comlineContactsText: "Wir unterstützen Sie gerne! Kontaktieren Sie uns einfach.",
  accountLabel: "Ihr Konto",
  createOrder: "Auftrag erstellen",
  customers: "Kunden",
  transferArchive: "Transferarchiv",
  transfers: "Transfers",
  transfer: "Transfer",
  settings: "Einstellungen",
  addLicenseForNextRenewal: "Renewal-Lizenz für nächstes Renewal hinzufügen.",
  canNotAddLicenseForNextRenewal: "Renewal-Lizenz können nur zwischen 30 und 3 Tagen vor dem Renewal hinzugefügt werden.",
  addLicense: "Renewal-Lizenz hinzufügen",
  addForAFee: "Kostenpflichtig hinzufügen",
  addLicenseText: 'Dies ist kein Auftrag. Das gewählte Produkt wird erst mit dem nächsten Renewal gebucht. Die Anzahl kann jederzeit im Kunden unter "Lizenzen" verändert werden oder die Lizenz für das Renewal deaktivieert werden.',
  contacts: "Ansprechpartner",
  language: "Sprache",
  help: "Hilfe",
  faq: "FAQ",
  signout: "Abmelden",
  save: "Speichern",
  addContact: "Ansprechpartner hinzufügen",
  yourLimit: "Ihr Limit",
  delete: "Löschen",
  edit: "Bearbeiten",
  faqText: `
    Auf dieser Seite finden Sie Antworten auf die am häufigsten gestellten Fragen.<br />
    Klicken Sie einfach auf die Frage um die entsprechende Antwort zu erhalten.`,
  furtherQuestions: "Sie haben weitere Fragen?",
  contactUs: "Kein Problem! Kontaktieren Sie uns einfach.",
  active: "Aktiv",
  pending: "Ausstehend",
  inactive: "Inaktiv",
  waitingForRenewal: "Warte auf Renewal",
  nextRenewal: "Warte auf Renewal",
  leaved: "Umgezogen",
  statusOfCustomer: "Status des Kunden.",
  inactiveInvalidAddress: "Inaktiv - Adresse ist ungültig",
  inactiveAccountBlocked: "Inaktiv - Konto ist gesperrt",
  inactiveCustomerAlreadyExists:
    "Inaktiv - Der Kunde existiert bereits mit demselben Firmennamen und primärem Administrator",
  complete: "Abgeschlossen",
  transferNotSubmitted: "Noch nicht eingereicht",
  open: "In Bearbeitung",
  failed: "Gescheitert",
  canceled: "Storniert",
  cancel: "Stornieren",
  failedDistributorInactive: "Gescheitert - Distributor ist inaktiv",
  failedResellerInactive: "Gescheitert - Reseller ist inaktiv",
  failedCustomerInactive: "Gescheitert - Kunde ist inaktiv",
  failedCustomerIdInavild: "Gescheitert - Kunden-ID ist ungültig",
  error: "Fehler",
  success: "Erfolg",
  priceInfo: "Preisinfo",
  rrp: "UVP",
  limitWarning: `<strong>Ihr verfügbares Limit beträgt: {availableLimit}</strong><br />
  Ihr Limit ist niedrig, möglicherweise können Sie Ihre Bestellung nicht ausführen.`,
  activeSubscriptions: "Aktive Lizenzen",
  usedSubscriptions: "Freie Lizenzen",
  usedLicenses: "in Verwendung",
  unUsedLicenses: "verfügbar",
  volumeLevel: "Lizenzen-Rabattstufe",
  subscriptions: "Lizenzen",
  purchasePrices: "Einkaufspreis",
  customerRequestedPurchaseHeadline: "Ihr Kunde hat weitere Produkte zum Kauf angefordert.",
  customerRequestedPurchaseInfo: "Ihr Kunde hat über die Admin Konsole mindestens ein weiteres Produkt hinzugefügt. Für Mengen und weitere Informationen wenden Sie sich bitte an Ihren Kunden oder suchen Sie in Ihrem E-Mail Posteingang nach der Betreffzeile \"Ein Kunde hat einen Kauf angefordert\". Um einen reibungslosen Ablauf zu garantieren, platzieren Sie bitte innerhalb der nächsten 7 Tage einen entsprechenden Auftrag.",
  customerRequestedPurchaseText: "Bitte überprüfen Sie ihre E-Mails oder halten Sie Rücksprache mit Ihrem Kunden.",
  customerRequestedAPurchase: "Ihr Kunde hat dieses Produkt zum Kauf angefordert!",
  noData: "Keine Daten vorhanden.",
  purchaseRequested: "Kauf angefordert",
  requestedAt: "Angefordert am",
  messageUnusedLicenses:"Kunde besitzt noch {quantity} ungenutzte Lizenzen!",
  subscription: "Lizenz",
  orderHistory: "Auftragshistorie",
  anniversaryDateNotSet: "Noch nicht festgelegt",
  pieces: "Stk.",
  product: "Produkt",
  quantity: "Menge",
  requestPrices: "Preise anfragen",
  confirmSignUpMessage: `<strong>Vielen Dank</strong><br /><br />Ihre Registrierung wird nun von einem Mitarbeiter geprüft. Sie erhalten eine Nachricht nachdem die Prüfung erfolgt ist.`,
  createCustomer: "Neukunde erstellen",
  createCustomerInfoText: `Auf dieser Seite können Sie einen neuen Kunden zum Adobe VIPMP hinzufügen.<br />
  <strong class="text-body">Achtung:</strong> Um einen bestehenden VIP-Kunden zum VIPMP zu transferieren klicken Sie bitte`,
  changeableInAdminConsoleInfo: "Nachträglich nur in der Adobe Admin Console änderbar",
  customerCreated: "Der Kunde wurde erstellt.",
  createOrderSuccessInfo: "Vielen Dank für Ihren Auftrag",
  create: "Erstellen",
  newOrderFor: "Neuer Auftrag für Kunde",
  bestPriceInfo: "Wir ermitteln den bestmöglichen Preis für Sie.",
  bestPriceDeterminedInfo: "Wir haben den bestmöglichen Preis für Sie ermittelt.",
  summary: "Zusammenfassung",
  customer: "Kunde",
  price: "Preis",
  totalPrice: "Gesamtpreis",
  totalPriceRrp: "Gesamtpreis (UVP)",
  margin: "Marge",
  exclTax: "exkl. Umsatzsteuer",
  manufacturerNumber: "Herstellernummer",
  orderNumber: "Bestellnummer",
  optional: "Optional",
  orderNow: "Kostenpflichtig bestellen",
  order: "Auftrag",
  activeCustomers: "Aktive Kunden",
  activeCustomersCreateOrderInfo: "Hier sehen Sie Ihre aktiven Kunden, für die Sie Aufträge erstellen können.",
  browse: "Durchsuchen",
  emptyFilteredText: "Es gibt keinen Datensatz, der Ihrer Eingabe entspricht",
  noCustomers: "Es sind keine Kunden vorhanden",
  ordersInProgress: "Aufträge in Bearbeitung",
  ordersInProgressText: "Hier sehen Sie Ihre bereits begonnenen Aufträge. Sie können diese fortsetzen oder löschen.",
  noOrdersText: "Es sind keine Aufträge vorhanden",
  abort: "Abbrechen",
  deleteOrderText: "Möchten Sie diesen Auftrag wirklich löschen?",
  numberOfProductsInCart: "Es befinden sich <strong>{quantity}</strong> Produkte im Warenkorb.",
  grid: "Raster",
  list: "Liste",
  customerOrdersText: "Dies ist die Auftragshistorie des Kunden.",
  customerOrdersHint: `<strong class="text-body">Tipp:</strong> Klicken Sie auf die Auftragsnummer um die Positionen zu sehen.`,
  orders:"Bestellungen",
  allOrdersText:"Dies ist Ihre gesamte Auftragshistorie.",
  allOrdersHint: `<strong class="text-body">Tipp:</strong> Klicken Sie auf die Auftragsnummer um die Positionen zu sehen.`,
  searchByOrderId: "Suche nach Order-ID",
  renewal: "Renewal",
  doYouWantTocancelThisOrder: `Sie möchten den Auftrag <strong>{cancelOrderId}</strong> stornieren?`,
  doYouWantTocancelThisLineItem: `Sie möchten die Position <strong>{lineItemIndex}</strong> aus dem Auftrag <strong>{cancelOrderId}</strong> stornieren?`,
  pleaseEnterReason: "Bitte geben Sie hier einen Grund ein",
  customerSubscriptionsText: "Hier sehen Sie alle Lizenzen des Kunden und können diese bei Bedarf verwalten.",
  searchByProductOrSubscriptionId: "Suche nach Produkt oder Lizenz-ID",
  noSubscriptions: "Es sind keine Lizenzen vorhanden",
  subscriptionId: "Lizenz-ID",
  willBeRenewedOnAnniversaryDate: "werden erneuert",
  willNotBeRenewedOnAnniversaryDate: "Wird nicht erneuert",
  autoRenewal: "Auto-Renewal",
  manageAutoRenewal: "Auto-Renewal verwalten",
  shoppingCart: "Warenkorb",
  creationTime: "Erstellungszeitpunkt",
  anniversaryDate: "Stichtag",
  endDate: "Enddatum",
  status: "Status",
  orderId: "Order-ID",
  creationDate: "Erstellungsdatum",
  activationDate: "Aktivierungsdatum",
  type: "Typ",
  customerId: "Customer-ID",
  membershipId: "VIP-Membership-ID",
  transferId: "Transfer-ID",
  submitTransfer: "Einreichen",
  transferVipSubscriptions: "Kunde von VIP zu VIPMP übertragen",
  transfersText: `Auf dieser Seite sehen Sie eine Übersicht Ihrer Transfers.`,
  searchByMembershipIdCusomterCustomerId: "Suche nach Membership-ID, Kunde oder Customer-ID",
  searchByCustomerCustomerId: "Suche nach Kunde oder Customer-ID",
  transfersText2:
    "Auf dieser Seite können Sie berechtigte VIP-Lizenzen eines Kunden zum VIP-Marketplace übertragen.<br /><strong>Ein Formular zur Einholung der benötigten Genehmigung Ihres Endkunden finden Sie <a href=\"/declaration_of_consent_data_transfer_adobe_certified_reseller.pdf\" target=\"_blank\">hier</a>.</strong>",
  transfersCheckBoxText: `Ich bin berechtigt diesen Transfer auszuführen.<br />
  Mir ist bewusst, dass bei Migration innerhalb des Renewal-Zeitfensters, eine Rückführung des Kunden in VIP Legacy nicht möglich ist.<br />
  <strong class="text-danger">Achtung: Dieser Vorgang lässt sich nicht rückgängig machen!</strong> `,
  showEligibleSubscriptions: "Berechtigte Lizenzen anzeigen",
  transferEligibleSubscriptions: "Berechtigte Lizenzen übertragen",
  transfersText3: "Auf dieser Seite sehen Sie alle für einen Transfer berechtigten Lizenzen zu der VIP-Membership-ID: <b>%{membershipID}</b>",
  here: "hier",
  customersText: `Auf dieser Seite sehen Sie eine Übersicht Ihrer Kunden und können diese ggfs. verwalten.<br />
  Über die Schaltfläche Neukunde erstellen können Sie einen neuen Kunden zum Adobe VIP-Marketplace hinzufügen.`,
  pricePerYear: "Preis pro Jahr",
  daysPerYear: "Tage pro Jahr",
  daysUntilAnniversaryDate: "Tage bis zum Stichtag",
  createAccount: "Zugang erstellen",
  createAccountText: "Sie können sich nun Ihren Zugang für den Adobe Cloud Store erstellen.",
  confirmPassword: "Passwort wiederholen",
  requirements: "Anforderungen",
  requirementsText: `
  Min. 8 Zeichen<br />
  Min. 2 kleine Buchstaben<br />
  Min. 2 große Buchstaben<br />
  Min. 2 Zahlen`,
  passwordInsecure: "Unsicheres Passwort",
  passwordsNotIdentical: "Passwörter sind nicht identisch",
  youAlreadyHaveAnAccount: "Sie haben bereits einen Zugang?",
  loginText: "Melden Sie sich an",
  youCanLoginWithYourNewPassword: "Sie können sich nun mit Ihren neuen Zugangsdaten anmelden.",
  toTheLoginForm: "Zum Anmeldeformular",
  offer: "Angebot",
  printOffer: "Angebot herunterladen",
  newOfferForCustomer: "Neues Angebot für Kunde",
  systemErrorText:
    "Aufgrund eines Systemfehlers bei Adobe kann es aktuell zu Beeinträchtigungen kommen.<br />Wir bitten um Ihr Verständnis.",
  renewalOffer: "Angebot für Verlängerung",
  autoRenewalInfo: `<strong class="text-danger text-uppercase">Achtung</strong><br />Wenn Sie das Auto-Renewal deaktivieren, wird die Lizenz am Stichtag nicht mehr automatisch erneuert. <strong>Dies kann zum Verlust der Lizenz führen.</strong>`,
  autoRenewalIs: "Das Auto-Renewal ist ",
  enabled: "aktiv",
  disabled: "inaktiv",
  renewTheFollowingAmount: "Die folgende Menge zukünftig erneuern",
  renewTheFollowingAmountInfo: `<strong class="text-danger text-uppercase">Achtung</strong><br />
    Wenn die Erneuerungsmenge explizit festgelegt wurde, wird die Erneuerungsmenge <strong>nicht durch zusätzliche Bestellungen oder Stornierungen erhöht oder verringert</strong> (bis das
    Abonnement inaktiv wird).<br /><br />
    Wenn die Erneuerungsmenge zum Zeitpunkt der Erneuerung höher als die aktuelle Menge ist, werden die zusätzlichen
    Lizenzen in die Erneuerung eingeschlossen. Wenn sie niedriger ist, werden die Lizenzen bei der Verlängerung
    entfernt.<br /><br />
    Wenn die Erneuerungsmenge nicht explizit festgelegt wurde (leeres Feld), wird die aktuelle Menge zum Zeitpunkt der Verlängerung verlängert. <strong>Die Erneuerungsmenge wird durch zusätzliche Bestellungen oder Stornierungen erhöht oder verringert.</strong>`,
  updateCustomers: "Kunden aktualisieren",
  exportCustomerData: "Kundendaten exportieren",
  showHiddenCustomers: "Ausgeblendete Kunden anzeigen",
  hideHiddenCustomers: "Ausgeblendete Kunden ausblenden",
  hideCustomer: "Kunde ausblenden",
  showCostomer: "Kunde anzeigen",
  pleaseWait: "Bitte warten. Dieser Prozess kann einige Zeit in Anspruch nehmen.",
  furtherMailAddressesHeadline: "Weitere E-Mail-Adressen",
  furtherMailAddresses: "Weitere E-Mail-Adressen",
  furtherMailAddressesPlaceholder: "E-Mail-Adresse hinzufügen...",
  notofocationSettings: "Benachrichtigungseinstellungen",
  threeYCNotification: "3-Years-Commit Benachrichtigungen",
  threeYCNotificationAditionalInformation: "Hier können sie Wählen an wievielen Tagen Sie eine benachrichtigung vor dem Auslaufen von 3YC erhalten wollen.",
  multiselectText: 'Halten Sie <kbd class="notranslate" translate="no">ctrl</kbd> (Windows) oder <kbd class="notranslate" translate="no">cmd</kbd> (Mac) gedrückt für Mehrfachauswahl.',
  selectedDays: "Gewählte Tage",
  enterValidEMail: "Keine gültige E-Mail-Adresse",
  notificationStatusOn: "ist an",
  notificationStatusOff: "ist aus",
  customerStatusChangeNotification: "Kunden Statusänderung",
  customerStatusChangeNotificationInformation: "Versand einer E-Mail wenn sich der Status eines Kunden ändert.",
  orderSuccessNotification: "Bestellbestätigung",
  orderSuccessNotificationInformation: "Versand einer E-Mail wenn eine Bestellung erfolgreich war.",
  renewalSuccessNotification: "Renewal wurde ausgeführt",
  renewalSuccessNotificationInformation: "Versand einer E-Mail wenn ein Renewal ausgeführt wurde.",
  renewalReminderNotification: "Renewalerinnerung",
  renewalReminderNotificationInformation: "Versendet an den gewählten Tagen vor dem Renewal eine Erinnerung.",
  deactivatedRenewalReminderNotification: "Renewalerinnerung (deaktiviert)",
  deactivatedRenewalReminderNotificationInformation:"Versendet an den gewählten Tagen vor dem Renewal eine Erinnerung für deaktivierte subscriptions.",
  maintenance: "Aufgrund von Wartungsarbeiten, ist der Adobe Cloud Store vorübergehend nicht verwendbar. Bitte schauen Sie später noch einmal vorbei.",
  threeyearcommit: "3-Year-Commit-Treueprogramm",
  threeyearcommitInfo: `Das 3-Year-Commit-Treueprogramm kann <strong>nicht</strong> mit Extended-Term Paid-Up-Front kombiniert werden.`,
  minimumAmountSubscriptions: "Mindestmenge Lizenzen",
  minimumAmountConsumables: "Mindestmenge Verbrauchsgüter(Sign)",
  volumeDiscountCode: "Discount Code",
  customerNotIn3YC: "Der Kunde ist aktuell nicht im 3-Year-Commit-Treueprogramm.",
  customerIsIn3YCFromDateToDate: "Der Kunde ist im 3-Year-Commit-Treueprogramm vom {startDate} bis {endDate}.",
  numberOfDaysTillEndOf3YC: "Eine Erneuerung ist noch {numberOfDaysTillEnd} Tage möglich.",
  extend3YC: "Einladung für Erneuerung des 3-Year-Commit",
  extentionRequested: "Eine Erneuerung wurde angefragt.",
  customerDeclinedExtention: "Der Kunde hat die Erneuerung abgelehnt.",
  customerAcceptedExtentionNextTermStartsAt: "Der Kunde hat der Erneuerung Zugestimmt. Die nächste Laufzeit beginnt am {startDate}.",
  waitingForApproval: `<strong>Warten auf Bestätigung des Kunden.</strong><br /><br />Der Kunde hat die Einladung in das 3-Year-Commit-Treueprogramm noch nicht angenommen.`,
  customerAccepted3YC: `<strong>Der Kunde hat die Einladung angenommen.</strong><br /><br />Stellen Sie sicher, dass die erforderlichen Mindestmengen innerhalb der nächsten 7 Tage erreicht werden, damit Ihr Kunde von den Vergünstigungen profitieren kann.`,
  customerDeclined3YC: `<strong>Die Einladung wurde abgelehnt.</strong><br /><br />Der Kunde hat die Einladung in das 3-Year-Commit-Treueprogramm abgelehnt.`,
  customerNotIn3YCSendInvitation: "Laden Sie den Kunden ein, dem 3-Year-Commit-Treueprogramm beizutreten, und sichern Sie attraktive Preisvorteile durch langfristige Bindung. Alternativ können Sie die bestehende Mindestabnahmemenge anpassen, um zusätzliche Vorteile zu aktivieren.",
  send3YCInvitation: "3-Year-Commit Einladung/Änderung versenden",
  set3YCLevelInfo: `<p>Wählen Sie die Menge der Lizenzen und der Verbrauchsgüter für das 3-Year-Commit-Treueprogramm.</p><p>Der Kunde erhält eine E-Mail, damit er die Einladung annehmen kann.</p><p><u>Dies ist keine Erneuerung des 3YC!</u></p>`,
  renew3YCLevelInfo: `<p>Wählen Sie die Menge der Lizenzen und der Verbrauchsgüter für die Erneuerung des 3-Year-Commit-Treueprogramm.</p><p>Der Kunde erhält eine E-Mail, damit er die Einladung annehmen kann.</p>`,
  extend3YCNow: "Kunden für verlängerung einladen",
  send3YCInvitationNow: "Einladung versenden",
  send3YCChange: "Änderung der 3YC-Menge",
  commitmentRequestRenew: "Erneuerung",
  commitmentRequestInvitation: "Einladung",
  customerHasActive3yc: "Kunde ist im 3YC Programm.",
  extendedTerm: "Extended-Term Paid-Up-Front",
  threeYearsCommitMinQuantity: "Die Mindestmenge ist 10",
  extendedTermDescription: `Hier können Sie dem Kunden einen gewünschten Stichtag zuweisen.<br />
  Dieser muss zwischen 1 und 3 Jahren (Commercial) bzw. 1 und 4 Jahren (Education und Government) in der Zukunft liegen.<br />
  Der Kunde bezahlt die gekauften Lizenzen dann für den gewählten Zeitraum im Vorraus.<br />
  Nach dem Ablauf des gewählten Zeitraums werden die Lizenzen des Kunden in dem regulären jährlichen Turnus erneuert.`,
  noAnniversaryDateSelected:"Kein Stichtag gewählt",
  anniversaryDateCannotBeChanged: "Der Stichtag kann nach dem ersten Auftrag nicht mehr geändert werden.",
  basicOption: "Volume Discount",
  threeYCOption: "3-Year-Commit",
  expiry: "Ablauf",
  yourResellerId: "Ihre Reseller-ID",
  signedInAs: "Angemeldet als",
  yourDashboard: "Ihr Dashboard",
  keyFactsAtAGlance: "Das Wichtigste auf einen Blick.",
  noNewsAtTheMoment: `<p>Aktuell gibt es keine Neuigkeiten.</p><strong>Sie sind bestens informiert!</strong>`,
  pending3ycInvitations: "Ausstehende Einladungen in das 3-Year-Commit-Treueprogramm",
  noPending3ycInvitations: `<p>Aktuell gibt es keine ausstehenden Einladungen in das 3-Year-Commit-Treueprogramm.</p><strong>Gute Arbeit!</strong>`,
  vipmpReleaseTimeline: "Adobe VIPMP Release Timeline",
  upcomingRenewals: "Bevorstehende Verlängerungen",
  today: "Heute",
  noUpcomingRenewals: `<p>Für heute, den {date} sind keine Verlängerungen geplant.</p><strong>Vielleicht morgen?</strong>`,
  newVersion: "Eine neue Version ist verfügbar.",
  update: "Update",
  marketSegment: "Marktsegment",
  marketSubSegment: "Subsegment",
  changeableIfCustomerHasNoActiveSubscriptions: "Nachträglich nur änderbar, wenn der Kunde noch keine aktiven Lizenzen hat.",
  ignoreOrderReturn: "Retourenrecht ignorieren",
  ignoreOrderReturnText: "Mir ist bewusst, dass bei Migration das Retourenrecht für in VIP Legacy bestellte Produkte erlischt.",
  expireOpenPas: "Offene Kaufautorisierungen löschen",
  expireOpenPasText: "Mir ist bewusst, dass bei Migration bereits erstellte Kaufautorisierungen im Kunden Account erlöschen.",
  purchaseRequests: "Kaufanforderungen",
  showAll: "Alle anzeigen",
  noPurchaseRequests: `<p>Aktuell gibt es keine offenen Kaufanforderungen.</p><strong>Gute Arbeit!</strong>`,
  search: "Suche",
  backToHomepage: "Zurück zur Startseite",
  cancelOrder: "Auftrag stornieren",
  timeZoneNoticePrice: "Aus technischen Gründen werden die Preise anhand der Zeitzone America/Los_Angeles berechnet.",
  timeZoneNoticeDate: "Aus technischen Gründen wird das Datum anhand der Zeitzone America/Los_Angeles gesetzt.",
  transferCustomer: "Kunde übertragen",
  vipToVipmp: "VIP zu VIPMP",
  resellerToReseller: "Reseller zu Reseller",
  transferCustomerFromResellertoReseller: "Kunde von Reseller zu Reseller übertragen",
  transferCustomerFromResellertoResellerInfo: "Auf dieser Seite können Sie einen Kunden von einem Reseller zu einem anderen Reseller übertragen.",
  approvalCode: "Genehmigungscode",
  requestedBy: "Angefordert von",
  previewTransfer: "Transfervorschau",
  requestedByInfo: "E-Mail-Adresse der Person, die den Transfer angefordert hat.",
  adobeTechnicalProblems: "Aufgrund einer technischen Störung bei Adobe ist der Shop derzeit nur eingeschränkt nutzbar. Vielen Dank für Ihr Verständnis.",
  linkedMembership: "Linked-Membership",
  requied: "Pflichtfelder",
  linkedMembershipType: "Linked-Membership Typ",
  linkedMembershipName: "Name",
  id: "ID",
  standard: "Standard",
  consortium: "Cunsortium",
  linkedMemberShipDesciption: "Linked Membership ermöglicht es Organisationen, Käufe über verknüpfte Kundenkonten zu bündeln, um bessere Mengenrabatte zu erzielen, während Lizenzen separat verwaltet werden. Es gibt Standardmitgliedschaften für unabhängige Einheiten und Konsortien für Gruppen mit gemeinsamen Zielen. Ein Hauptkonto (Owner) verwaltet die Verknüpfung und lädt Mitglieder ein, wodurch Kosten durch gemeinsame Lizenzierung gesenkt werden​.",
  knownCustomers: "Bekannte verknüpfte Kunden:",
  HGOText: "Um das High Growth Offer (HGO) nutzen zu können, müssen Kunden am Three-Year Commit (3YC) Programm teilnehmen und eine Mindestbestellmenge (MOQ) erfüllen. Diese beträgt je nach Angebot 100, 250 oder 500 Lizenzen. Nur Kunden aus den Marktsegmenten \"Commercial\" und \"Government\", die Acrobat Pro oder Standard nutzen, sind berechtigt.",
  MOQ: "Mindestbestellmenge (MOQ)",
  threeYCRequied: "Three-Year Commit (3YC) erforderlich",
  CreateThreeYearsCommitment: "zum 3-Year-Commit",
  variant: "Variante",
  minimumOrderQuantity: "HGO-Mindestbestellmenge",
  customerNotices: "Platz für Notizen...",
  done: "Fertig",
  theCustomerWillGetInformed :"Vielen Dank.<br /><br />Der Kunde wird informiert.",
  ShowTheOptionalSettings: "Optionale Felder anzeigen",
  YouNeedAHigherMinQuant: "Sie benötigen eine höhere Mindestbestellmenge",
  customerDiscountText: "Geben Sie hier den prozentualen Rabatt auf den UVP netto für Ihren Endkunden an (z.B. 1,25%).",
  discount: "Rabatt",
  discountErrorText: "Kein gültiger Rabatt",

};
