<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("orders") }}
        </h3>
        <b-button :to="{ name: 'CreateOrderSelectCustomerView' }" variant="dark"
          ><fa-icon :icon="['fa-regular', 'fa-circle-plus']" class="mr-2"></fa-icon>{{ $t("createOrder") }}</b-button
        >
      </b-container>
    </div>
    <b-container class="pt-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col>
          <p class="text-muted">{{ $t("allOrdersText") }}</p>
          <p class="mb-4 text-muted" v-html="$t('allOrdersHint')"></p>

          <b-card no-body>
            <b-card-header class="p-3 d-flex">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-white pr-0"
                    ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
                  ></b-input-group-text>
                </template>
                <b-input
                  :placeholder="$t('searchByOrderId')"
                  class="border-left-0"
                  v-model="filter"
                ></b-input>
              </b-input-group>

              <b-pagination
                class="ml-3 mb-0"
                v-show="ordersLength > perPage"
                v-model="currentPage"
                :total-rows="ordersLength"
                :per-page="perPage"
                aria-controls="table"
              ></b-pagination>

            </b-card-header>
            <b-table
              ref="orders"
              :items="ordersLoaded"
              :fields="fields"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :per-page="perPage"
              :current-page="currentPage"
              class="mb-0"
              show-empty
              :empty-filtered-text="$t('emptyFilteredText')"
              :empty-text="$t('noOrdersText')"
              sort-icon-left
              :no-local-sorting="true"
            >
              <template #empty="scope">
                <div class="d-flex justify-content-center" v-if="isBusy"><b-spinner class="text-center" label="Spinning" variant="secondary"></b-spinner></div>
                <div v-else class="text-center font-italic">{{ scope.emptyText }}</div>
              </template>

              <template #emptyfiltered="scope">
                <div class="text-center font-italic">
                  {{ scope.emptyFilteredText }}
                </div>
              </template>

              <template #cell(orderInfo)="data">
                <!-- <pre>{{ data.item }}</pre> -->
                <b-link v-on:click="selectOrder(data.item)">
                  {{ data.item.orderId }} </b-link
                ><br />
                <small
                  v-if="data.item.referenceOrderId"
                  class="text-muted"
                  v-b-tooltip.hover
                  :title="$t('orderNumber')"
                  v-html="data.item.reference"
                ></small><br />
                <small
                  v-if="data.item.orderType === 'RETURN' && data.item.referenceOrderId"
                  class="text-muted"
                  v-b-tooltip.hover
                  :title="'Reference Order-ID'"
                  v-html="'Reference Order-ID: ' + data.item.referenceOrderId"
                ></small>
              </template>


              <template #cell(customer)="data">
                 {{ data.item.customer.companyProfile.companyName  }}<br>
                  <router-link :to="{ name: 'CustomerSubscriptionsView', params: { customerId: data.item.customer.customerId  } }">
                    <small class="text-muted">{{ data.item.customer.customerId }}</small>
                  </router-link>
              </template>

              <template #cell(status)="data">
                <div :class="statusClass(data.item.status)">
                  <fa-icon
                    :icon="['fa-regular', statusIcon(data.item.status)]"
                    class="mr-1"
                  ></fa-icon
                  >{{ data.item.status | orderStatus }}
                </div>
              </template>

              <template #cell(creationTime)="data">
                <fa-icon :icon="['fa-regular','fa-clock']" class="mr-1"></fa-icon>{{ data.item.creationDate | date }}
              </template>

              <template #cell(price)="data">
                <span v-if="data.item.orderType === 'RETURN'">-</span>{{ data.item.price | currency }}
              </template>

              <template #cell(type)="data">
                <span v-if="data.item.orderType === 'TRANSFER'">{{ $t("transfer") }}</span>
                <span v-if="data.item.orderType === 'RENEWAL'">{{ $t("renewal") }}</span>
                <span v-if="data.item.orderType === 'NEW'">Order</span>
                <span v-if="data.item.orderType === 'RETURN'">Return</span>
              </template>

              <template #cell(actions)="data">
                <div
                  v-if="
                    data.item.status === '1000' &&
                    data.item.orderType !== 'TRANSFER' &&
                    data.item.orderType !== 'RETURN' &&
                    !isOlderThanFourteenDays(data.item.creationDate)
                  "
                  class="d-flex justify-content-end"
                >
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    v-on:click="showCancelConfirm(data.item)"
                  >
                    {{ $t("cancel") }}
                  </b-button>
                </div>
              </template>
            </b-table>

            <b-card-footer v-show="ordersLength > perPage">
              <div class="d-flex">
                <b-pagination
                  class="mb-0"
                  v-model="currentPage"
                  :total-rows="ordersLength"
                  :per-page="perPage"
                  aria-controls="table"
                ></b-pagination>
              </div>
            </b-card-footer>

          </b-card>

          <b-modal
            ref="order-modal"
            :content-class="contentClass"
            :footer-class="footerClass"
            :body-class="bodyClass"
            hide-footer
            :header-class="headerClass"
            size="xl"
          >
            <template #modal-title>
              <span v-b-tooltip.hover.bottom="$t('orderId')">{{
                selectedOrder.orderId
              }}</span
              ><br />
              <small
                v-if="selectedOrder?.reference"
                class="text-muted"
                v-b-tooltip.hover
                :title="$t('orderNumber')"
                v-html="selectedOrder.reference.replace('undefined', '')"
              ></small>
            </template>
            <table class="table mb-0">
              <thead>
                <tr>
                  <th>{{ $t("product") }}</th>
                  <th>{{ $t("status") }}</th>
                  <th>{{ $t("quantity") }}</th>
                  <th>{{ $t("price") }}</th>
                  <th>{{ $t("rrp") }}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(lineItem, lineItemIndex) in selectedOrder.lineItems"
                  :key="`item-${lineItemIndex}`"
                >
                <!-- <pre>{{ lineItem }}</pre><br> -->
                  <td class="d-flex align-items-center">
                    <div class="border rounded p-1 mr-3" v-if="lineItem.imageUrl">
                      <b-img-lazy
                        :src="lineItem.imageUrl"
                        :alt="lineItem.productName"
                      ></b-img-lazy>
                    </div>
                    <div>
                      {{ lineItem.productName | productNameWithoutLevel }}<br />
                      <small
                        class="text-muted"
                        v-b-tooltip.hover
                        :title="$t('manufacturerNumber')"
                        >{{ lineItem.offerId }}</small
                      >
                    </div>
                  </td>
                  <td>
                    <div :class="statusClass(lineItem.status)">
                      <fa-icon
                        :icon="['fa-regular', statusIcon(lineItem.status)]"
                        class="mr-1"
                      ></fa-icon
                      >{{ lineItem.status | orderStatus }}
                    </div>
                  </td>
                  <td>{{ lineItem.quantity }}</td>
                  <td>
                    <span v-if="selectedOrder.orderType === 'RETURN'">-</span>{{ lineItem.price | currency }}                     
                      <!-- <fa-icon
                        :icon="['fa-regular', 'fa-circle-info']"
                        class="ml-2 text-muted"
                        v-b-tooltip.hover="`${formatCurrency(lineItem?.foundPrice?.pricePerYear || 0)} (${$t('pricePerYear')}) / ${daysInYear(lineItem.orderDate, lineItem.isRenewal)} (${$t('daysPerYear')}) * ${lineItem.daysBetween} (${$t('daysUntilAnniversaryDate')}) * ${lineItem.quantity} (${$t('quantity')}) = ${formatCurrency(lineItem.price)} `"
                      ></fa-icon> -->
                  </td>
                  <td><span v-if="lineItem?.foundPrice?.endcustomerSalesPrice">{{ (lineItem?.foundPrice?.endcustomerSalesPrice * lineItem.quantity) | currency }}</span></td>
                  <td class="text-right">
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        v-on:click="showLineItemCancelConfirm(lineItemIndex, selectedOrder)"
                        v-if="
                          lineItem.status !== '1008' &&
                          selectedOrder.orderType !== 'TRANSFER' &&
                          selectedOrder.orderType !== 'RETURN' &&
                          !isOlderThanFourteenDays(selectedOrder.creationDate)
                        "
                      >
                        {{ $t("cancel") }}
                      </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-modal>

          <b-modal
            ref="cancel-modal"
            hide-header
            :content-class="contentClass"
            :footer-class="footerClass"
            :body-class="bodyClass"
            no-close-on-esc
            no-close-on-backdrop
            v-on:cancel="clearCancelData()"
            v-on:hidden="clearCancelData()"
          >
            <div
              v-if="orderToCancel !== null"
              v-html="
                $t('doYouWantTocancelThisOrder', { cancelOrderId: orderToCancel.orderId })
              "
            ></div>
            <template #modal-footer="{ cancel }">
              <b-button variant="outline-secondary" v-on:click="cancel()">
                {{ $t("abort") }}
              </b-button>

              <submit-button-component
                v-on:click="cancelOrder()"
                :variant="'danger'"
                :state="submitButtonState"
                :label="$t('cancel')"
                :block="false"
              ></submit-button-component>
            </template>
          </b-modal>

          <b-modal
            ref="line-item-cancel-modal"
            hide-header
            :content-class="contentClass"
            :footer-class="footerClass"
            :body-class="bodyClass"
            no-close-on-esc
            no-close-on-backdrop
            v-on:cancel="clearCancelData()"
            v-on:hidden="clearCancelData()"
          >
            <div
              v-if="orderToCancel !== null"
              v-html="
                $t('doYouWantTocancelThisLineItem', { cancelOrderId: orderToCancel.orderId, lineItemIndex: lineItemToCancel })
              "
            ></div>

            <template #modal-footer="{ cancel }">
              <b-button variant="outline-secondary" v-on:click="cancel()">
                {{ $t("abort") }}
              </b-button>

              <submit-button-component
                v-on:click="cancelOrder()"
                :variant="'danger'"
                :state="submitButtonState"
                :label="$t('cancelOrder')"
                :block="false"
              ></submit-button-component>
            </template>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import { orderBy } from 'lodash-es';
import moment from "moment-timezone";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { query, collection, getDocs, where} from "firebase/firestore";
import { getProductsFromServer } from "../plugins/customModules/getProducts.js";

export default {
  components: { SubmitButtonComponent },
  name: "ordersView",
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    orders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      ordersLength: 0,
      perPage: 25,
      currentPage: 1,
      isBusy: true,
      filter: "",
      isCancelling: false,
      sortBy: "companyName",
      sortDesc: false,
      ordersLoaded: [],
      fields: [
        {
          key: "customer",
          label: this.$t("customer"),
          sortable: false,
        },
        {
          key: "orderInfo",
          label: this.$t("order"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: false,
        },
        {
          key: "creationTime",
          label: this.$t("creationTime"),
          sortable: false,
        },
        {
          key: "price",
          label: this.$t("price"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("type"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      selectedOrder: {
        orderId: "",
        lineItems: [],
      },
      orderToCancel: null,
      lineItemToCancel: null,
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
    };
  },
  computed: {
    submitButtonState() {
      if (this.isCancelling === true) {
        return "working";
      }

      if (this.cancelOrderReason === "") {
        return "missingFields";
      }

      return "";
    },
     tableDataArrayFiltered() {
      this.countUp()
      let filteredData = this.ordersLoaded || [];
      if(filteredData && filteredData.length > 0){
        console.log(filteredData)
        if(this.sortBy === "companyName"){  
          filteredData.sort((a, b) => {
            return ( this.sortDesc ? -1 : 1) * a?.customer?.companyProfile.companyName.localeCompare(b.customer?.companyProfile.companyName);
          });
        }

        if(this.sortBy === "creationTime"){
          filteredData.sort((a, b) => {
            const aModded = a.creationTime && a.creationTime.split("-")[0]+""+a.creationTime.split("-")[1]+""+a.creationTime.split("-")[2]
            const bModded = b.creationTime && b.creationTime.split("-")[0]+""+b.creationTime.split("-")[1]+""+b.creationTime.split("-")[2]
            return ( this.sortDesc ? -1 : 1) * aModded.localeCompare(bModded);
          });
        }

        if(this.sortBy === "status"){
          filteredData.sort((a, b) => {
            return ( this.sortDesc ? -1 : 1) * a.status.localeCompare(b.status);
          });
        }
      }

      if (
        this.filterDebounce !== "" &&
        this.filterDebounce !== undefined &&
        this.filterDebounce !== null
      ) {
        const toreturn = filteredData.filter((obj) => {
          let check = false;
          let each = Object.keys(obj);
            for (var i = 0; i < each.length; i++) {
              String(obj[each[i]])
                .toLowerCase()
                .includes(String(this.filterDebounce.toLowerCase())) && (check = true);
            }

            if (obj.companyProfile !== undefined && obj.companyProfile[0] !== "") {
              let orderEach = Object.keys(obj.companyProfile);
              for (var l = 0; l < orderEach.length; l++) {
                  String(obj.companyProfile[orderEach[l]])
                    .toLowerCase()
                    .includes(String(this.filterDebounce.toLowerCase())) &&
                      (check = true );
              }
            }
            
          return check;
        });
        return(toreturn)
      } else {
        return filteredData;
      }
    },
    rows() {
      return this.getOrders().length || 0;
    },
  },
  watch: {
    orders() {
      this.$refs.orders.refresh();
    },
  },
  methods: {
    countUp(){
      this.count = this.count + 1
    },
    async getAllProducts(orderId){
      console.log("orderId", orderId)
      const ref = collection(firestore, "orders/"+orderId+"/products/");
      let products = await getDocs(ref);
      products = products.docs;
      products = products.map((product) => {
        return product.data()
      })
      return products
    },
    async getOrders() {
      let products = await getProductsFromServer(this.$store.state.resellerData.resellerId);

      const resellerOrdersColRef = query(collection(firestore, "orders"), where("reseller.resellerId", "==", this.$store.state.resellerData.resellerId), where("order.orderId", "!=", ""));
      let resellerOrdersFromCollection = getDocs(resellerOrdersColRef);

      const resellerPricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      let resellerPrices = getDocs(resellerPricesColRef);

      const promises = [
        products,
        resellerOrdersFromCollection,
        resellerPrices
      ];

      return await Promise.all(promises)
        .then(async (responses) => {


          resellerOrdersFromCollection = [];
          if (!responses[1].empty) {
            responses[1].forEach((doc) => {
              // console.log("resellerOrdersFromCollection", doc.data())
              resellerOrdersFromCollection.push(doc.data());
            });
          }

          resellerPrices = [];
          if (!responses[2].empty) {
            responses[2].forEach((doc) => {
              resellerPrices.push(doc.data());     
            });
          }
          let mergeOrderHistory = resellerOrdersFromCollection;
          this.orders.forEach(order=>{
            if ( !mergeOrderHistory.find(x=>x.order.orderId === order.orderId) ){
              mergeOrderHistory.push({
                creationDate: order.creationDate,
                customer: {},
                deluxeOrder: {},
                finished: false,
                order: order,
                partnerData: {}
              })
            }
          })
          let orderHistory = [];
          const prodcutsOfOrder = responses?.[1]?.docs?.[0]?.id ? await this.getAllProducts(responses[1].docs[0].id) : [];

          if (mergeOrderHistory.length > 0) {
            mergeOrderHistory.forEach((orderFromCollection) => {
              const order = orderFromCollection.order;
              console.log(order)
              order["price"] = 0;
              order["reference"] = "&nbsp;";
              order["customer"] = orderFromCollection.customer;


              // Fügt Lineitems die passende Produktbezeichnung und das passende Produktbild hinzu
              order.lineItems.forEach((lineItem) => {
                const matchingProduct = products.filter(
                  (product) =>
                    product.manufacturerItemNumber === lineItem.offerId
                );
                let FoundPrice
                prodcutsOfOrder.forEach(el=>
                  el.prices.forEach(price=>{
                    price.offerId === lineItem.offerId && (FoundPrice = price)
                  })
                )

                lineItem["foundPrice"] = FoundPrice
                lineItem["isRenewal"] = order.orderType === 'RENEWAL'
                lineItem["orderDate"] = order.orderDate
                if (matchingProduct.length > 0) {
                  /* const matchingResellerPrice = resellerPrices.filter(
                    (price) =>
                      price.itemNumber === matchingProduct[0].itemNumber
                  ); */

                  //lineItem["price"] = matchingResellerPrice[0].price;
                  lineItem["price"] = 0;
                  lineItem["productName"] = matchingProduct[0].productName;
                  lineItem["imageUrl"] = matchingProduct[0]?.partnerData?.imageUrl || matchingProduct[0].imageUrl;

                  // order["price"] += matchingResellerPrice[0].price * lineItem.quantity;
                } else {
                  lineItem["productName"] = "";
                  lineItem["imageUrl"] = "";
                  lineItem["price"] = 0;
                }
              });

              // Fügt einer Order die Bestellnummer und den Geamtpreis und den Lineitems die Preise hinzu
              const matchingOrdersFromCollection =
                mergeOrderHistory.filter(
                  (resellerOrder) =>
                    resellerOrder.order.orderId === order?.orderId
                );

              if (matchingOrdersFromCollection.length > 0) {
                //order.price = 0;
                const DaysOfOrder = this.calcDays(order.creationDate, matchingOrdersFromCollection[0]?.customer.cotermDate)
                order.daysBetween = DaysOfOrder
                order.referenceOrderId = "";

                if (matchingOrdersFromCollection[0].order.referenceOrderId !== undefined) {
                  order.referenceOrderId = matchingOrdersFromCollection[0].order.referenceOrderId;
                }

                order.reference =
                  matchingOrdersFromCollection[0].deluxeOrder[
                    "order_reference"
                  ];

                order.lineItems.forEach((lineItem) => {
                  lineItem.daysBetween = DaysOfOrder

                  const matchingOrderLines = matchingOrdersFromCollection[0]?.deluxeOrder?.["order_lines"] && 
                    matchingOrdersFromCollection[0].deluxeOrder[
                      "order_lines"
                    ].filter(
                      (orderLine) =>
                        orderLine["vipmp_offer_id"] === lineItem?.offerId
                    );

                  if(matchingOrderLines?.[0]) {
                    // console.log(matchingOrdersFromCollection[0].order, lineItem)
                    lineItem.price =
                    matchingOrderLines[0]["unit_cost"] *
                    matchingOrderLines[0]["quantity"]
                  } else {
                    lineItem.price = 0;
                  }

                  order.price += lineItem.price;
                });
              }

              if (
                (order?.orderId && order?.orderId.toLowerCase().includes(this.filter.toLowerCase())) ||
                (order?.reference && order?.reference.toLowerCase().includes(this.filter.toLowerCase()))
              ) {
                orderHistory.push(order);
              }
            });
          }

          this.isBusy = false
          this.ordersLength = orderHistory.length;
          return orderBy(
            orderHistory,
            (item) => {
              return moment(item.creationDate).unix();
            },
            "desc"
          );
        })
        .catch((error) => {
          console.error(error);
          this.isBusy = false
          return [];
        });
        
    },
    selectOrder(data) {
      this.selectedOrder = data;
// console.log(data)
      this.$refs["order-modal"].show();
    },
    showCancelConfirm(data) {
      this.orderToCancel = data;

      this.$refs["cancel-modal"].show();
    },
    showLineItemCancelConfirm(lineItemIndex, selectedOrder) {
      this.lineItemToCancel = lineItemIndex;
      this.orderToCancel = selectedOrder;

      this.$refs["line-item-cancel-modal"].show();
    },
    clearCancelData() {
      this.orderToCancel = null;
      this.lineItemToCancel = null;
    },
    cancelOrder() {
      let lineItemsToRemove = [];

      this.orderToCancel.lineItems.forEach((lineItem) => {
        lineItemsToRemove.push(lineItem);
      });

      let completeCancel = true;

      if (this.lineItemToCancel !== null) {
        lineItemsToRemove = lineItemsToRemove.splice(this.lineItemToCancel, 1);
        completeCancel = false;
      }

      this.isCancelling = true;
      const cancelOrder = httpsCallable(functions, "cancelOrder");
      cancelOrder({
        customerNumber: this.$store.state.resellerData.customernumber,
        resellerId: this.$store.state.resellerData.resellerId,
        customerId: this.orderToCancel.customerId,
        complete: completeCancel,
        body: {
          referenceOrderId: this.orderToCancel.orderId,
          orderType: "RETURN",
          externalReferenceId: this.orderToCancel.externalReferenceId,
          currencyCode: this.orderToCancel.currencyCode,
          lineItems: lineItemsToRemove,
        },
      })
        .then(() => {
          this.isCancelling = false;
          this.$refs["cancel-modal"].hide();
          this.$refs["line-item-cancel-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Der Auftrag wurde storniert.",
            show: true,
            noFooter: false,
          });
        })
        .catch((error) => {
          this.isCancelling = false;
          console.error(error);

          if (error.additionalDetails?.length > 0) {
            error.message += "<br />" + JSON.stringify(error.additionalDetails)
          }

          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    isOlderThanFourteenDays(creationDate) {
      const difference = moment.tz(creationDate, "America/Los_Angeles").diff(moment.tz("America/Los_Angeles").format("YYYY-MM-DD"), "days");

      if (difference < -14) {
        return true;
      } else {
        return false;
      }
    },
    calcDays(creationDate, cotermDate) {
      console.log( moment.tz(cotermDate, "America/Los_Angeles"), moment.tz(creationDate, "America/Los_Angeles"))
      const daysBetweenTodayAndCotermDate = moment.tz(cotermDate, "America/Los_Angeles").diff(moment.tz(creationDate, "America/Los_Angeles"), "days");
      console.log(daysBetweenTodayAndCotermDate)
      return (daysBetweenTodayAndCotermDate+1)
    },
    formatCurrency(val, maximumFractionDigits) {
      return this.$options.filters.currency(val, maximumFractionDigits)
    },
    daysInYear(date, isRenewal) {
      if (date === "" || isRenewal) {
        const referenceDate = moment.tz(date, "America/Los_Angeles"); 
        return moment(referenceDate).add(1, "year").diff(referenceDate, "days");
      } else {
        const referenceDate = moment.tz(date, "America/Los_Angeles").subtract(1, "year");
        // console.log(moment.tz(this.order.customer.cotermDate, "America/Los_Angeles").diff(referenceDate, "days"))
        return moment.tz(date, "America/Los_Angeles").diff(referenceDate, "days");
      }
    },
    calcPricePerYear(price, daysInYear) {
      return (price / daysInYear)
    },
  },
  async mounted(){
    this.ordersLoaded = await this.getOrders()
  }
};
</script>

<style>
.table img {
  max-height: 60px;
}
</style>
