<template>
  <div>
    <HGOComponent :customer="customer"/>
  </div>
</template>

<script>
import HGOComponent from "../components/HGOComponent.vue";

export default {
  name: "CustomerHGOView",
  components: {
    HGOComponent
  },
  props: {
    customer: Object
  },
  data() {
    return {
      benefits: null,
      isWorking: true,
    };
  },
};
</script>
