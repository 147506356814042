<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("customers") }}
        </h3>
        <b-button :to="{ name: 'CreateCustomerView' }" variant="dark"
          ><fa-icon :icon="['fa-regular', 'fa-circle-plus']" class="mr-2"></fa-icon>{{ $t("createCustomer") }}</b-button
        >
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('customersText')"></div>
      <b-card no-body>
        <b-card-header class="p-3 d-flex">

          <b-button variant="dark" v-b-tooltip.hover.topright="$t('updateCustomers')" v-b-modal="'update-customers-modal'" :disabled="customers.length == 0">
            <fa-layers>
              <fa-icon :icon="['fa-regular', 'fa-user']"></fa-icon>
              <fa-icon :icon="['fa-solid', 'fa-arrows-rotate']" transform="shrink-6 right-10 up-3"></fa-icon>
            </fa-layers>
          </b-button>

          <b-button variant="dark" v-b-tooltip.hover.topright="$t('exportCustomerData')" v-b-modal="'export-customers-modal'" class="ml-3" :disabled="customers.length == 0">
            <fa-layers>
              <fa-icon :icon="['fa-regular', 'fa-user']"></fa-icon>
              <fa-icon :icon="['fa-solid', 'fa-down-to-line']" transform="shrink-6 right-10 up-3"></fa-icon>
            </fa-layers>
          </b-button>

          <b-button variant="dark" v-on:click="showHiddenCustomers()" v-b-tooltip.hover.topright="$t('showHiddenCustomers')" class="ml-3" v-if="!showAllCustomers">
            <fa-layers>
              <fa-icon :icon="['fa-regular', 'fa-user']"></fa-icon>
              <fa-icon :icon="['fa-regular', 'eye']" transform="shrink-6 right-10 up-3"></fa-icon>
            </fa-layers>
          </b-button>

          <b-button variant="dark" v-on:click="hideHiddenCustomers()" v-b-tooltip.hover.topright="$t('hideHiddenCustomers')" class="ml-3" v-else>
            <fa-layers>
              <fa-icon :icon="['fa-regular', 'fa-user']"></fa-icon>
              <fa-icon :icon="['fa-regular', 'eye-slash']" transform="shrink-6 right-10 up-3"></fa-icon>
            </fa-layers>
          </b-button>

          <b-input-group class="ml-3">
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('searchByCustomerCustomerId')" class="border-left-0" @input="debounceInput"></b-input>
          </b-input-group>

          <b-pagination
            class="ml-3 mb-0"
            v-show="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table"
          ></b-pagination>

        </b-card-header>
        <b-table
          ref="table"
          id="table"
          :items="tableDataArrayFiltered"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="mb-0"
          show-empty
          :empty-filtered-text="$t('emptyFilteredText')"
          :empty-text="$t('noCustomers')"
          sort-icon-left
          :per-page="perPage"
          :current-page="currentPage"
          :no-local-sorting="true"
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>

          <template #emptyfiltered="scope">
            <div class="text-center font-italic">{{ scope.emptyFilteredText }}</div>
          </template>

          <template #cell(companyName)="data">
            <router-link :to="{ name: 'CustomerSubscriptionsView', params: { customerId: data.item.customerId } }">
              {{ data.item.companyProfile.companyName }}
            </router-link>
            <b-badge class="ml-1" style="display: inline;" variant="dark" v-if="threeYearsCommitStatus(data.item.benefits)">3YC</b-badge>
            <b-badge class="ml-1" style="display: inline;" variant="secondary" v-if="data.item.companyProfile.marketSegment">{{ data.item.companyProfile.marketSegment}}</b-badge>
            <div>
              <small class="text-muted" v-b-tooltip.hover :title="$t('customerId')">
                <fa-icon :icon="['fa-regular', 'fa-id-card']" class="mr-1"></fa-icon>{{ data.item.customerId }}<br />
                <template v-if="data.item.resellerReferenceId">{{$t("resellerReferenceId")}} {{ data.item.resellerReferenceId }}</template>
              </small>
            </div>
          </template>

          <template #cell(cotermDateTimestamp)="data">
            <span v-if="data.item.cotermDate">
              <fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon
              ><span v-html="cotermDate(data.item.cotermDate)"></span><br />
              <template v-if="data.item.renewalOrderNumber"><small class="text-muted">{{$t("renewalOrderNumberShort")}} {{ data.item.renewalOrderNumber }}</small></template>
            </span>
          </template>

          <template #cell(status)="data">
            <div :class="statusClass(data.item.status)">
              <span v-b-tooltip.hover :title="$t('statusOfCustomer')">
              <fa-icon :icon="['fa-regular', statusIcon(data.item.status)]" class="mr-1"></fa-icon
              >{{ data.item.status | accountStatus }}</span>
            </div>
          </template>

          <template #cell(visibility)="data">
            <div class="d-flex justify-content-end">
              <b-button variant="dark" v-on:click="hideCustomer(data.item.customerId)" v-if="data.item.visible" v-b-tooltip.hover.top="$t('hideCustomer')"><fa-icon :icon="['fa-regular', 'eye-slash']"></fa-icon></b-button>
              <b-button variant="dark" v-on:click="showCustomer(data.item.customerId)" v-else v-b-tooltip.hover.top="$t('showCostomer')"><fa-icon :icon="['fa-regular', 'eye']"></fa-icon></b-button>
            </div>
          </template>
        </b-table>

        <b-card-footer v-show="rows > perPage">
          <div class="d-flex">
            <b-pagination
              class="mb-0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table"
            ></b-pagination>
          </div>
        </b-card-footer>
      </b-card>
    </b-container>

    <b-modal
      id="update-customers-modal"
      ref="update-customers-modal"
      hide-footer
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:show="updateCustomerData()"
      :title="$t('updateCustomers')"
    >
      <div class="w-100">
        <p>{{$t("pleaseWait")}}</p>
        <b-progress :max="customers.length">
          <b-progress-bar :value="updatedCustomersCount" :label="`${updatedCustomersCount} / ${customers.length}`"></b-progress-bar>
        </b-progress>
      </div>
    </b-modal>

    <b-modal
      id="export-customers-modal"
      ref="export-customers-modal"
      hide-footer
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:show="exportCustomerData()"
      :title="$t('exportCustomerData')"
    >
      <div class="w-100">
         <p>{{$t("pleaseWait")}}</p>
        <b-progress :max="customers.length">
          <b-progress-bar :value="exportedCustomersCount" :label="`${exportedCustomersCount} / ${customers.length}`"></b-progress-bar>
        </b-progress>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { orderBy } from "lodash-es";
import moment from "moment";
import { firestore, functions, auth } from "../plugins/firebase";
import { collection, where, query, getDocs, doc, updateDoc, arrayUnion, arrayRemove, serverTimestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { utils, writeFileXLSX } from 'xlsx';
import { debounce } from "debounce";

export default {
  name: "CustomersView",
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      filter: "",
      filterDebounce: "",
      sortBy: "companyName",
      sortDesc: false,
      fields: [
        {
          key: "companyName",
          label: this.$t("customer"),
          sortable: true,
        },
        {
          key: "cotermDateTimestamp",
          label: this.$t("anniversaryDate"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: true,
        },
        {
          key: "visibility",
          label: "",
          sortable: false,
        },
      ],
      customers: [],
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4 d-flex"],
      updatedCustomersCount: 0,
      exportedCustomersCount: 0,
      showAllCustomers: false,
      count: 0,
    };
  },
  async mounted() {
    let customersSorting = sessionStorage.getItem("customersSorting");
    if (customersSorting !== null) {
      customersSorting = JSON.parse(customersSorting);
      this.sortBy = customersSorting.sortBy;
      this.sortDesc = customersSorting.sortDesc;
    }
    await this.getCustomers()
    this.customers[0]
  },
  watch: {
    sortBy(value) {
      sessionStorage.setItem(
        "customersSorting",
        JSON.stringify({
          sortBy: value,
          sortDesc: this.sortDesc,
        })
      );
    },
    sortDesc(value) {
      sessionStorage.setItem(
        "customersSorting",
        JSON.stringify({
          sortBy: this.sortBy,
          sortDesc: value,
        })
      );
    },
    updatedCustomersCount(value) {
      if (value === this.customers.length) {
        setTimeout(() => {
          this.$refs["update-customers-modal"].hide();
          this.$refs.table.refresh();
          this.updatedCustomersCount = 0;
        }, 2000)
      }
    }, 
    exportedCustomersCount(value) {
      if (value === this.customers.length) {
        setTimeout(() => {
          this.$refs["export-customers-modal"].hide();
          this.$refs.table.refresh();
          this.exportedCustomersCount = 0;
        }, 2000)
      }
    },
    "$store.state.resellerData.hiddenCustomers": function() {
      this.$root.$emit('bv::refresh::table', 'table')
    }
  },
  computed: {
    tableDataArrayFiltered() {
      this.countUp()
      let filteredData = this.customers;

      if(this.sortBy === "companyName"){
        filteredData.sort((a, b) => {
          return ( this.sortDesc ? -1 : 1) * a.companyProfile.companyName.localeCompare(b.companyProfile.companyName);
        });
      }

      if(this.sortBy === "cotermDateTimestamp"){
        filteredData.sort((a, b) => {
          const aModded = a.cotermDate && a.cotermDate.split("-")[0]+""+a.cotermDate.split("-")[1]+""+a.cotermDate.split("-")[2]
          const bModded = b.cotermDate && b.cotermDate.split("-")[0]+""+b.cotermDate.split("-")[1]+""+b.cotermDate.split("-")[2]
          return ( this.sortDesc ? -1 : 1) * aModded.localeCompare(bModded);
        });
      }

      if(this.sortBy === "status"){
        filteredData.sort((a, b) => {
          return ( this.sortDesc ? -1 : 1) * a.status.localeCompare(b.status);
        });
      }

      if (
        this.filterDebounce !== "" &&
        this.filterDebounce !== undefined &&
        this.filterDebounce !== null
      ) {
        const toreturn = filteredData.filter((obj) => {
          let check = false;
          let each = Object.keys(obj);
            for (var i = 0; i < each.length; i++) {
              String(obj[each[i]])
                .toLowerCase()
                .includes(String(this.filterDebounce.toLowerCase())) && (check = true);
            }

            if (obj.companyProfile !== undefined && obj.companyProfile[0] !== "") {
              let orderEach = Object.keys(obj.companyProfile);
              for (var l = 0; l < orderEach.length; l++) {
                  String(obj.companyProfile[orderEach[l]])
                    .toLowerCase()
                    .includes(String(this.filterDebounce.toLowerCase())) &&
                      (check = true );
              }
            }
            
          return check;
        });
        return(toreturn)
      } else {
        return filteredData;
      }
    },
    rows() {
        return this.tableDataArrayFiltered.length || 0
    }
  },
  methods: {
    countUp(){
      this.count = this.count + 1
    },
    debounceInput: debounce(function (e) {
        this.filterDebounce = e;
      }, 300),
    returnExternalReferenceId(customernumberInResellersystem, externalReferenceId) {
      if (customernumberInResellersystem) {
        return customernumberInResellersystem;
      } else {
        return externalReferenceId;
      }
    },
    async getCustomers() {
      const colRef = query(
        collection(firestore, "customers"),
        where("resellerId", "==", this.$store.state.resellerData.resellerId),
      );

      const querySnapshot = await getDocs(colRef);
      this.customers = [];

      querySnapshot.forEach((doc) => {
        const customerData = doc.data();
        if (this.$store.state.resellerData.hiddenCustomers.includes(doc.data().customerId)) {
          customerData.visible = false;
        } else {
          customerData.visible = true;
        }

        let volumeLevel = "01";
        let consumableLevel = "T1";

        if (customerData.discounts !== undefined) {
          customerData.discounts.forEach((discount) => {
            if (discount.offerType === "LICENSE") {
              volumeLevel = discount.level;
            }

            if (discount.offerType === "CONSUMABLES") {
              consumableLevel = discount.level;
            }
          });
        }

        const customer = {
          externalReferenceId: this.returnExternalReferenceId(customerData?.customernumberInResellersystem, customerData.externalReferenceId),
          companyProfile: customerData.companyProfile,
          status: customerData.status,
          customerId: customerData.customerId,
          cotermDate: customerData.cotermDate,
          cotermDateTimestamp: moment(customerData.cotermDate).unix(),
          volumeLevel: volumeLevel,
          consumableLevel: consumableLevel,
          creationDate: (customerData.creationDate.split('T'))[0],
          visible: customerData.visible,
          benefits: customerData.benefits,
          resellerReferenceId: customerData.resellerReferenceId,
          renewalOrderNumber: customerData.renewalOrderNumber,
        };

        if (this.showAllCustomers) {
          this.customers.push(customer);
        } else if (customerData.visible) {
          this.customers.push(customer);
        }
      });

    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    cotermDate(cotermDate) {
      if (cotermDate === "") {
        return "&nbsp;";
      }
      return this.$options.filters.date(cotermDate);
    },
    async updateCustomerData() {
      for await (const customer of this.customers) {
        const customerData = await this.getCustomerAccount(customer.customerId);
        await this.updateCustomerAccount(customerData);
        this.updatedCustomersCount++
      }
    },
    getCustomerAccount(customerId) {
      const customerAccount = httpsCallable(functions, "customerAccount");
      return customerAccount({
        customerId: customerId,
      })
        .then((result) => result.data)
        .catch(() => null);
    },
    async updateCustomerAccount(customerAccount) {
      const colRef = query(collection(firestore, "customers"), where("customerId", "==", customerAccount.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });
        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          updateDoc(docRef, {
            companyProfile: customerAccount.companyProfile,
            cotermDate: customerAccount.cotermDate,
            status: customerAccount.status,
            discounts: customerAccount.discounts,
            linkedMembership: customerAccount?.linkedMembership || false,
            resellerId: customerAccount.resellerId,
            externalReferenceId: customerAccount.externalReferenceId,
            benefits: this.createDatesInBenefitsObject(customerAccount.benefits),
            lastFetchFromAdobe: serverTimestamp(),
          });
        }
      }
    },
    createDatesInBenefitsObject(benefits) {
      const editedBenefits = [];
      if (benefits?.length > 0) {
        benefits.forEach((el) =>{
          const newEl = el;
console.log(newEl)
          if (newEl?.commitment) {
            newEl.commitment.startDate = new Date(el.commitment.startDate);
            newEl.commitment.endDate = new Date(el.commitment.endDate);
          }

          if (newEl?.commitmentRequest?.startDate) {
            newEl.commitmentRequest.startDate = new Date(el.commitmentRequest.startDate);
              }
          if (newEl?.commitmentRequest?.endDate) {
            newEl.commitmentRequest.endDate = new Date(el.commitmentRequest.endDate);
          }

          if (newEl?.recommitmentRequest?.startDate) {
            newEl.recommitmentRequest.startDate = new Date(el.recommitmentRequest.startDate);
          }
          if (newEl?.recommitmentRequest?.endDate) {
            newEl.recommitmentRequest.endDate = new Date(el.recommitmentRequest.endDate);
          }

          editedBenefits.push(newEl);
        });
      }
      return editedBenefits;
    },
    async exportCustomerData() {
      const result = [];
      this.customers.forEach((customer) => {
        customer.companyProfile.contacts.forEach((contact) => {
          result.push({
            companyName: customer.companyProfile.companyName,
            addressLine1: customer.companyProfile.address.addressLine1,
            addressLine2: customer.companyProfile.address.addressLine2,
            postalCode: customer.companyProfile.address.postalCode,
            city: customer.companyProfile.address.city,
            country: customer.companyProfile.address.country,
            phoneNumber: customer.companyProfile.address.phoneNumber,
            cotermDate: customer.cotermDate,
            creationDate: customer.creationDate,
            customerId: customer.customerId,
            resellerId: this.$store.state.resellerData.resellerId,
            status: customer.status,
            volumeLevel: customer.volumeLevel,
            consumableLevel: customer.consumableLevel,
            partnerCustomerNumber: customer.externalReferenceId,
            contactFirstName: contact.firstName,
            contactLastName: contact.lastName,
            contactEmail: contact.email,
            contactPhoneNumber: contact.phoneNumber,
          });
        });
        this.exportedCustomersCount++
      });
      const worksheet = utils.json_to_sheet(result);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-customers-export.xlsx");
    },
    async hideCustomer(customerId) {
      const docRef = doc(firestore, "resellers", auth.currentUser.uid);
      await updateDoc(docRef, {
        hiddenCustomers: arrayUnion(customerId),
      });
      this.customers = []
      this.getCustomers()
    },
    async showCustomer(customerId) {
      const docRef = doc(firestore, "resellers", auth.currentUser.uid);
      await updateDoc(docRef, {
        hiddenCustomers: arrayRemove(customerId),
      });
      this.customers = []
      this.getCustomers()
    },
    showHiddenCustomers() {
      this.showAllCustomers = true;
      this.customers = []
      this.getCustomers()
      this.$refs.table.refresh();
    },
    hideHiddenCustomers() {
      this.showAllCustomers = false;
      this.customers = []
      this.getCustomers()
      this.$refs.table.refresh();
    },
    threeYearsCommitStatus(benefits) {
      let status
      const threeYC = benefits.filter((d) => d.type === "THREE_YEAR_COMMIT") || false;
      status = threeYC[0]?.commitment?.status

      return status === "COMMITTED"
    }
  },
};
</script>
