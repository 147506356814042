<template>
  <b-container>
   
    <!-- Starten -->
    <div>

      <p class="mb-4 mt-4 text-muted" v-html="$t('HGOText')"></p>
      <div v-if="customerThreeYearsCommit?.commitment?.status !== 'ACTIVE' && 
                 customerThreeYearsCommit?.commitmentRequest?.status !== 'ACCEPTED' &&
                 customerThreeYearsCommit?.commitment?.status !== 'COMMITTED' &&
                 customerThreeYearsCommit?.commitmentRequest?.status !== 'COMMITTED' ">
        <b-alert variant="warning" show>{{ $t("threeYCRequied") }}</b-alert>
        <b-button class="mt-1 btn-dark" @click="goTo3yc()">{{ $t('CreateThreeYearsCommitment') }}</b-button>
      </div>

      <div v-else-if=" licenseQuantitie < 100">
        <b-alert variant="warning" show>{{ $t("YouNeedAHigherMinQuant") }}</b-alert>
        <b-button class="mt-1 btn-dark" @click="goTo3yc()">{{ $t('CreateThreeYearsCommitment') }}</b-button>
      </div>

      <b-form v-else>

        <b-form-group
          id="input-group-1 text-muted"
          :label="$t('product')"
          label-for="product">
          <b-form-select name="input-1" id="product" v-model="selectedProduct" :options="products" class="col-3"></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-1 text-muted"
          :label="$t('variant')"
          label-for="moq">
          <b-form-select name="input-1" id="moq" v-model="selectedVariante" :options="variante" class="col-3"></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-1 text-muted"
          :label="$t('language')"
          label-for="moq">
          <b-form-select name="input-1" id="moq" v-model="selectedLang" :options="lang" class="col-3"></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-1 text-muted"
          :label="$t('MOQ')"
          label-for="moq">
          <b-form-select name="input-1" id="moq" v-model="selectedMOQ" :options="filteredOptionsMOQ" class="col-3"></b-form-select>
        </b-form-group>
      
        <b-form-group
          id="input-group-1 text-muted"
          :label="$t('quantity')"
          label-for="quatity">
          <b-form-input type="number" id="quatity"  v-model="quantity" class="col-3"></b-form-input>
        </b-form-group>

        <div v-if="isSubmitting"  class="col-3" style="text-align: center;">
          <b-spinner label="Spinning" variant="secondary"></b-spinner>
        </div>

        <div v-else>
        <b-button @click="newOrder()" :disabled="chekNewOrderbility()" :variant="(chekNewOrderbility() ? '' : 'primary')">{{ $t("order") }}</b-button>
        &nbsp;
        <b-button @click="AddToRenewal()" :disabled="checkRenewalability()" :variant="(checkRenewalability() ? '' : 'primary')">{{ $t("renewal") }}</b-button>
        </div>  

      </b-form>
    </div>

  </b-container>
</template>

<script>
import moment from "moment-timezone";
import { functions, firestore } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { collection, addDoc } from "firebase/firestore";
import { getPrices } from "../plugins/customModules/getPrices.js";
import { getProductsFromServer } from "../plugins/customModules/getProducts.js";

export default {
  name: "HGOComponent",
  props: {
    customer: Object
  },
  computed: {
    filteredOptionsMOQ() {
    const filteredOptionsMOQInter = [];
    this.optionsMOQ.forEach(element => {
      if (element.text === '-' || 
          (element.value === "X" && this.licenseQuantitie >= 100) ||
          (element.value === "Y" && this.licenseQuantitie >= 250) ||
          (element.value === "Z" && this.licenseQuantitie >= 500)) {
        filteredOptionsMOQInter.push(element);
      }
    });
    return filteredOptionsMOQInter;
  },
  },
  data() {
    return {
      isSubmitting: false,
      quantity: 0,
      customerThreeYearsCommit: this.customer.benefits.filter(d => d.type === "THREE_YEAR_COMMIT")[0],
      licenseQuantitie: this.customer.benefits.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitmentRequest?.minimumQuantities?.filter(item => item.offerType === "LICENSE")?.map(item => item.quantity)?.[0] ?? [],
      selectedMOQ: null,
      selectedProduct: "AP",
      selectedVariante: null, 
      minQuantitiy: 0,
      selectedLang: null,
      products: [
        {value: "AP", text: 'Acrobat Pro'},
      ],
      productsWithPrices: [],
      eligibleOffers: [],
      orderType: "NEW",
      optionsMOQ: [
          {value: null, text: '-',},
          {value: "X", text:  'MOQ 100'},
          {value: "Y", text:  'MOQ 250'},
          {value: "Z", text:  'MOQ 500'}
        ],
      productCodes: {
        "5-11-0X3-0314300":	"65304521CA14X12",
        "5-11-0X3-0314350":	"65304522CA14X12",
        "5-11-1X3-0314300":	"65322648CA14X12",
        "5-11-1X3-0314350":	"65322649CA14X12",
        "5-31-0X3-0314300":	"30002137CC14X12",
        "5-31-0X3-0314350":	"30002136CC14X12",
        "5-31-1X3-0314300":	"30002129CC14X12",
        "5-31-1X3-0314350":	"30002130CC14X12",
        "5-11-0Y3-0314300":	"65304521CA14Y12",
        "5-11-0Y3-0314350":	"65304522CA14Y12",
        "5-11-1Y3-0314300":	"65322648CA14Y12",
        "5-11-1Y3-0314350":	"65322649CA14Y12",
        "5-31-0Y3-0314300":	"30002137CC14Y12",
        "5-31-0Y3-0314350":	"30002136CC14Y12",
        "5-31-1Y3-0314300":	"30002129CC14Y12",
        "5-31-1Y3-0314350":	"30002130CC14Y12",
        "5-11-0Z3-0314300":	"65304521CA14Z12",
        "5-11-0Z3-0314350":	"65304522CA14Z12",
        "5-11-1Z3-0314300":	"65322648CA14Z12",
        "5-11-1Z3-0314350":	"65322649CA14Z12",
        "5-31-0Z3-0314300":	"30002137CC14Z12",
        "5-31-0Z3-0314350":	"30002136CC14Z12",
        "5-31-1Z3-0314300":	"30002129CC14Z12",
        "5-31-1Z3-0314350":	"30002130CC14Z12",
      },
      variante: [
        {value: "0", text: 'Teams',},
        {value: "1", text:  'Enterprise',},
      ],
      lang: [
        {value: "0", text: 'German',},
        {value: "5", text:  'English',},
      ],
      order: {
        finished: false,
        customer: this.customer,
        reseller: {
          companyProfile: this.$store.state.resellerData.companyProfile,
          resellerId: this.$store.state.resellerData.resellerId,
          customernumber: this.$store.state.resellerData.customernumber,
        },
        deluxeOrder: null,
        creationDate: moment().tz("Europe/Berlin").format(),
        ttl: moment().tz("Europe/Berlin").add(7, "days").toDate(),
      },
      MOQCODE: 
      {
        "X": "MOQ_100",
        "Y": "MOQ_250",
        "Z": "MOQ_500",
      },
      optionsMOQRenewal: [],
      res: null,
      subscriptionId: null,
    }
  },
  methods: {
    goTo3yc(){
      this.$router.push({
        name: "Customer3YC",
        params: {
          customerId: this.customer.customerId,
        },
      });
    },
    goToSubscriptions(){
      this.$router.push({
        name: "CustomerSubscriptionsView",
        params: {
          customerId: this.customer.customerId,
        },
      });
    },
    async previewRenewalOrder() {
      console.log("previewRenewalOrder");
      const previewRenewalOrder = httpsCallable(functions, "previewRenewalOrder");
      try {
        const res = await previewRenewalOrder({
          customerId: this.customer.customerId,
          order: {
            orderType: "PREVIEW_RENEWAL",
          },
        });
        this.eligibleOffers = res.data.eligibleOffers;
        this.res = res
        console.log("eligibleOffers", this.res);

        if (this.eligibleOffers.find(el=>el.renewalCode === "MOQ_100")) {
          this.optionsMOQRenewal.push({value: "X", text:  'MOQ 100',})
        } 
        if (this.eligibleOffers.find(el=>el.renewalCode === "MOQ_250")) {
          this.optionsMOQRenewal.push({value: "Y", text:  'MOQ 250',})
        } 
        if (this.eligibleOffers.find(el=>el.renewalCode === "MOQ_500")) {
          this.optionsMOQRenewal.push({value: "Z", text:  'MOQ 500',})
        } 
        return res;
      } catch (error) {
        console.error(error); 
      }
    },
    makeClArtNo(){
      const variante = this.selectedVariante || false;
      const lang = this.selectedLang || false;
      const moq = this.selectedMOQ || false;
      const comGov = this.customer.companyProfile.marketSegment === "GOV" ? "3" : "1" //3 = GOV / 1 = COM

      return `5-${comGov}1-${variante}${moq}3-03143${lang}0`
    },
    async newOrder(){
      const adobeOfferId = this.productCodes[this.makeClArtNo()];
      console.log(adobeOfferId)


      if(this.selectedMOQ === null) {
        return; 
      }

      console.log("productsWithPrices", this.productsWithPrices);

      const items = this.productsWithPrices

      console.log(this.order);

      if (this.customer === null) return;
      const docRef = await addDoc(collection(firestore, "orders"), this.order)

      console.log("items", items)

      const item = items.find(item => item.offerId === adobeOfferId)

      console.log("item", item);
      await this.addProductToCart(item, docRef.id)

      this.$router.push({
        name: "CreateOrderCheckoutView",
        params: {
          orderId: docRef.id,
        },
      });

    },
    async addProductToCart(item, orderId){

      item["quantity"] = this.quantity;

      const productsRef = collection(firestore, "orders", orderId, "products");

console.log(productsRef)

      addDoc(productsRef, item);
      
    },
    async AddToRenewal(){
      this.isSubmitting = true;
      const RenewalObject = {
                                "enabled": true,
                                "renewalQuantity": this.quantity,
                                "renewalCode": this.MOQCODE[this.selectedMOQ]
                              }
                            

      const updateSubscriptionAutoRenewal = httpsCallable(functions, "updateSubscriptionAutoRenewal");
      await updateSubscriptionAutoRenewal({
        customerId: this.customer.customerId,
        subscriptionId: this.subscriptionId,
        autoRenewal: RenewalObject,
      })
        .then(() => {
          this.$store.dispatch("alertSuccess", {
          message: this.$t("done"),
          show: true,
          noFooter: false,
        })
          this.goToSubscriptions();
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
          this.isSubmitting = false;
        });

    },
    async getProducts() {
      console.info("loading Products")
      const products = await getProductsFromServer(this.$store.state.resellerData.resellerId);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        this.$router.replace({ name: "CreateOrderSelectCustomerView" });
      }
      const filteredProducts = products.filter(product => Object.keys(this.productCodes).includes(product.itemNumber) )
      const correctedFilteredProducts = filteredProducts.map(product => {
        product["offerId"] = product.manufacturerItemNumber;
        return product;
      })

      console.log("filteredProducts", correctedFilteredProducts)

      const productsWithPrices = await getPrices(this.customer.customerId ,{
        benefits: this.order.customer.benefits,
        cotermDate: this.order.customer.cotermDate,
        referenceDate: moment().tz("America/Los_Angeles").add(1, "days").format("YYYY-MM-DD"),
        discounts: this.order.customer.discounts,
        products: correctedFilteredProducts,
        resellerId: this.order.customer.resellerId,
        marketSegment: this.order.customer.companyProfile.marketSegment,
      });

      this.productsWithPrices = productsWithPrices.data;
      console.log('this productsWithPrices', this.productsWithPrices)
      this.isWorking = false;
    },
    checkRenewalability(){
      const adobeOfferId = this.productCodes[this.makeClArtNo()];
      const included = this.eligibleOffers.findIndex(each=>each.offerId === adobeOfferId)

      const subscriptionIdIndex = adobeOfferId && this.res?.data?.lineItems?.findIndex(each=>each.offerId.slice(0, -5) === adobeOfferId.slice(0, -5))
      console.log("subscriptionIdIndex", subscriptionIdIndex)
      this.subscriptionId = subscriptionIdIndex >= 0 && this.res?.data?.lineItems[subscriptionIdIndex].subscriptionId

      return this.selectedLang == null || this.selectedMOQ == null || this.selectedVariante == null || included === -1 || this.subscriptionId == null
    },
    chekNewOrderbility(){
      return this.minQuantitiy > this.quantity || this.selectedMOQ == null || this.selectedLang == null || this.selectedVariante == null
    }
  },
  mounted() {
    this.previewRenewalOrder();   
    this.getProducts();
  },
};
</script>
