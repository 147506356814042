<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">{{ $t("transferCustomerFromResellertoReseller") }}</h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <b-form v-on:submit.prevent="submit">
        <b-card class="mb-4" v-if="lineItems.length > 0">
          <b-table :items="lineItems" :fields="fields" class="my-0"></b-table>
        </b-card>

        <b-card footer-class="border-0 bg-light">
          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("customerId") }}</label>
            </b-col>
            <b-col>
              <b-form-input
                v-model="customerId"
                disabled
              ></b-form-input>
            </b-col>
          </b-row>

          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component
                :state="submitButtonState"
                :label="$t('transferCustomer')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { query, collection, where, getDocs } from "firebase/firestore";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ChangeOfResellerCommitView",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      approvalCode: "",
      requestedBy: "",
      lineItems: [],
      customerId: "",
      resellerId: "",
      fields: [
        {
          key: "productName",
          label: this.$t("product"),
          sortable: false,
          formatter: (value) => {
            return this.$options.filters.productNameWithoutLevel(value);
          },
        },
        {
          key: "quantity",
          label: this.$t("quantity"),
          sortable: false,
        },
        {
          key: "subscriptionId",
          label: this.$t("subscriptionId"),
          sortable: false,
        },
        {
          key: "renewalDate",
          label: this.$t("anniversaryDate"),
          sortable: false,
          formatter: (value) => {
            return this.$options.filters.date(value);
          },
        },
      ],
    };
  },
  async beforeMount() {
    if (this.$store.state.temporary.changeOfReseller) {
      const {requestedBy, previewData} = this.$store.state.temporary.changeOfReseller;
      const {lineItems, approval, customerId, resellerId} = previewData;

      this.requestedBy = requestedBy;
      this.customerId = customerId;
      this.resellerId = resellerId;
      this.approvalCode = approval.code;

      const promises = lineItems.map((lineItem) => this.fetchProductName(lineItem));
      await Promise.allSettled(promises).then((result) => {
        this.lineItems = result.map((item, index) => {
          return {
            ...lineItems[index],
            productName: item.value,
          };
        });
      });
    } else {
      this.$router.replace({
        name: "ChangeOfResellerPreviewView",
      });
    }
  },
  methods: {
    async fetchProductName(lineItem) {
      const colRef = collection(firestore, "products");
      const q = query(colRef, where("manufacturerItemNumber", "==", lineItem.offerId));
      const querySnapshot = await getDocs(q);
      const product = querySnapshot.docs[0].data() || "";
      return product.productName;
    },
    async submit() {
      this.isWorking = true;

      try {
        const changeOfReseller = httpsCallable(functions, "changeOfReseller");
        const response = await changeOfReseller({
          type: "RESELLER_CHANGE",
          action: "COMMIT",
          approvalCode: this.approvalCode,
          resellerId: this.resellerId,
          requestedBy: this.requestedBy,
        });

        console.log(response);

        const {customerId} = response.data;

        const addOrUpdateCustomer = httpsCallable(functions, "addOrUpdateCustomer");
        await addOrUpdateCustomer({customerId});

        this.$store.dispatch("alertSuccess", {
          message: "Der Resellerwechsel wurde erfolgreich durchgeführt.",
          show: true,
          noFooter: false,
        });

        this.$router.push({ name: "ChangeOfResellerPreviewView" });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        });
      } finally {
        this.isWorking = false;
      }
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (
        this.requestedBy === "" || 
        this.approvalCode === "" ||
        this.resellerId === ""
        ) {
        return "missingFields";
      }

      return "";
    },
  },
};
</script>
