export default {
  partnerStore: false,
  companyName: "ComLine",
  firebaseConfig: {
    apiKey: "AIzaSyCzc9CCIXwWhxycG0Set3or6RC3SjQBW5I",
    authDomain: "clgmbh-acs-demo.firebaseapp.com",
    projectId: "clgmbh-acs-demo",
    storageBucket: "clgmbh-acs-demo.appspot.com",
    messagingSenderId: "847135274595",
    appId: "1:847135274595:web:a533dbdb697eed6ac46a56"
  },
};