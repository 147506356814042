<template>
  <div v-if="order">
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex align-items-center">
            <b-button
              class="mr-3"
              variant="dark"
              :to="{name: 'CreateOrderSelectProductsView', params: {orderId: this.$route.params.orderId}}">
              <fa-icon :icon="['far', 'angle-left']"></fa-icon>
            </b-button>
            <h3 class="font-weight-bold mb-0">
              {{ order.customer.companyProfile.companyName }}
            </h3>
          </div>
        </div>
      </b-container>
    </div>
    <div class="bg-light pb-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("creationTime") }}</small>
            <div>
              <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
            </div>
          </div>

          <div class="d-flex flex-column">
            <small class="text-muted">
              {{ $t("anniversaryDate") }}
              <fa-icon
                :icon="['fa-regular', 'fa-circle-info']"
                class="ml-1 text-muted"
                v-b-tooltip.hover.top="this.$t('timeZoneNoticeDate')"></fa-icon>
            </small>
            <div>
              <fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>
              {{ order.customer.cotermDate | date }}
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <b-container class="pt-4" v-if="order && subscriptions !== null">
      <h4 class="mb-4">{{ $t("summary") }}</h4>
      <h4 class="mb-4 d-none d-print-block">{{ $t("offer") }}</h4>
      <b-card class="mb-4">
        <p class="font-weight-bold">{{ $t("customer") }}</p>
        {{ order.customer.companyProfile.companyName }}<br />
        {{ order.customer.companyProfile.address.addressLine1 }}<br />
        {{ order.customer.companyProfile.address.addressLine2 }}<br />
        {{ order.customer.companyProfile.address.postalCode }} {{ order.customer.companyProfile.address.city }}<br />
        {{ order.customer.companyProfile.address.country }}
      </b-card>

      <div class="mb-4 d-flex alert alert-primary align-items-center" v-if="isCalculating">
        <b-spinner label="Spinning" variant="primary" small class="mr-1"></b-spinner>
        {{ $t("bestPriceInfo") }}
      </div>
      <div class="mb-4 alert alert-success" v-else>
        <fa-icon :icon="['fa-regular', 'fa-circle-check']"></fa-icon> {{ $t("bestPriceDeterminedInfo") }}
      </div>

      <div class="alert alert-primary mb-4">{{ $t("timeZoneNoticePrice") }} {{ timeNowString }}</div>

      <b-card no-body class="mb-4">
        <table class="table mb-0">
          <thead>
            <tr>
              <th>{{ $t("product") }}</th>
              <th class="text-right">{{ $t("price") }}</th>
              <th class="text-right">{{ $t("quantity") }}</th>
              <th class="text-right">{{ $t("totalPrice") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in products" :key="`row-${index}`">
              <td>
                <div class="d-flex flex-column align-items-start">
                  <span class="badge badge-secondary mb-1" v-if="calcUnusedQuantity(item) > 0 && !isCalculating">
                    {{ $t("messageUnusedLicenses", {quantity: calcUnusedQuantity(item)}) }}
                  </span>

                  <span class="badge badge-secondary mb-1" v-if="calcUnusedQuantity(item) < 0 && !isCalculating">
                    {{ $t("customerRequestedAPurchase") }}
                  </span>

                  <span class="badge badge-dark mb-1" v-if="!isCalculating">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>
                    {{ discountType(findPriceToUse(item).offerId) }}
                    {{ extractVolumeLevelFromOfferId(findPriceToUse(item).offerId) }}
                  </span>
                  <span class="badge badge-info mb-1" v-if="!isCalculating && item.discountCode">
                    <small>Discount Code: </small> {{ item.discountCode }}
                  </span>

                  <div>{{ item.productName | productNameWithoutLevel }}</div>

                  <small class="text-muted" v-b-tooltip.hover :title="$t('manufacturerNumber')" v-if="!isCalculating">{{
                    findPriceToUse(item).offerId
                  }}</small>
                </div>
              </td>
              <td class="text-right" v-if="!item.productType.match(/^[678]0/)">
                <div v-if="!isCalculating">
                  <div>
                    {{ findPriceToUse(item).price | currency }}
                    <fa-icon
                      :icon="['fa-regular', 'fa-circle-info']"
                      class="ml-2 text-muted"
                      v-b-tooltip.hover="
                        `${formatCurrency(findPriceToUse(item).pricePerYear)} (${$t(
                          'pricePerYear'
                        )}) / ${daysInYear} (${$t('daysPerYear')}) * ${daysUntilCotermDate} (${$t(
                          'daysUntilAnniversaryDate'
                        )}) = ${formatCurrency(findPriceToUse(item).price)}`
                      "></fa-icon>
                  </div>
                  <small class="text-muted" v-if="!isCalculating"
                    >{{ $t("rrp") }} {{ findPriceToUse(item).endcustomerSalesPrice | currency }}</small
                  >
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">
                  {{ $t("rrp") }} {{ findPriceToUse(item).endcustomerSalesPrice | currency }}
                </div>
              </td>
              <td class="text-right" v-else>
                m
                <div v-if="!isCalculating">
                  <div>
                    {{ findPriceToUse(item).price | currency }}
                  </div>
                  <small class="text-muted"
                    >{{ $t("rrp") }} {{ findPriceToUse(item).endcustomerSalesPrice | currency }}</small
                  >
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">
                  {{ $t("rrp") }} {{ findPriceToUse(item).endcustomerSalesPrice | currency }}
                </div>
              </td>
              <td class="text-right">
                {{ item.quantity }}
              </td>
              <td class="text-right" v-if="!item.productType.match(/^[678]0/)">
                <div v-if="!isCalculating">
                  <div>
                    {{ calcPositionSum(findPriceToUse(item).price, item.quantity) | currency }}
                    <fa-icon
                      :icon="['fa-regular', 'fa-circle-info']"
                      class="ml-2 text-muted"
                      v-b-tooltip.hover="
                        `${formatCurrency(findPriceToUse(item).pricePerYear)} (${$t(
                          'pricePerYear'
                        )}) / ${daysInYear} (${$t('daysPerYear')}) * ${daysUntilCotermDate} (${$t(
                          'daysUntilAnniversaryDate'
                        )}) x ${item.quantity} (${$t('pieces')}) = ${formatCurrency(
                          calcPositionSum(findPriceToUse(item).price, item.quantity),
                          4
                        )}`
                      "></fa-icon>
                  </div>
                  <small class="text-muted"
                    >{{ $t("rrp") }}
                    {{ calcPositionSum(findPriceToUse(item).endcustomerSalesPrice, item.quantity) | currency }}</small
                  >
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">
                  {{ $t("rrp") }}
                  {{ calcPositionSum(findPriceToUse(item).endcustomerSalesPrice, item.quantity) | currency }}
                </div>
              </td>
              <td class="text-right" v-else>
                <div v-if="!isCalculating">
                  <div>
                    {{ calcPositionSum(findPriceToUse(item).price, item.quantity) | currency }}
                  </div>
                  <small class="text-muted"
                    >{{ $t("rrp") }}
                    {{ calcPositionSum(findPriceToUse(item).endcustomerSalesPrice, item.quantity) | currency }}</small
                  >
                </div>
                <b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
                <div class="d-none d-print-block text-muted" v-if="!isCalculating">
                  {{ $t("rrp") }}
                  {{ calcPositionSum(findPriceToUse(item).endcustomerSalesPrice, item.quantity) | currency }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-right p-3 border-top font-weight-bold" v-if="!isCalculating">
          <small class="text-muted" v-if="settings?.showExclTax">{{ $t("exclTax") }}</small
          ><br />
          <span class="mr-3">{{ $t("totalPrice") }}</span
          ><span>{{ calcShoppingCartSum(products) | currency }}</span
          ><br />
          <small class="mr-3 text-muted">{{ $t("totalPriceRrp") }}</small
          ><small class="text-muted">{{ calcShoppingCartRrpSum(products) | currency }}</small
          ><br />
          <small class="mr-3 text-muted"
            >{{ $t("margin") }}
            <fa-icon
              :icon="['fa-regular', 'fa-circle-info']"
              class="ml-2 text-muted"
              v-b-tooltip.hover="
                `${calcShoppingCartRrpSum(products).toFixed(2)} (${$t('totalPriceRrp')}) - ${calcShoppingCartSum(
                  products
                ).toFixed(2)} (${$t('totalPrice')}) = ${(
                  calcShoppingCartRrpSum(products) - calcShoppingCartSum(products)
                ).toFixed(2)} (${$t('margin')})`
              "></fa-icon>
          </small>
          <small class="text-muted">{{
            (calcShoppingCartRrpSum(products) - calcShoppingCartSum(products)) | currency
          }}</small>
        </div>
        <div class="text-right border-top p-3" v-else>
          <b-spinner label="Spinning" variant="secondary" small></b-spinner>
        </div>
        <div class="text-right p-3 border-top font-weight-bold d-none d-print-block">
          <span class="mr-3">{{ $t("totalPrice") }}</span
          ><span v-if="!isCalculating">{{ calcShoppingCartRrpSum(products) | currency }}</span
          ><b-spinner v-else label="Spinning" variant="secondary" small></b-spinner>
        </div>
      </b-card>

      <div class="mb-4 alert alert-warning" v-if="!allowedToOrder">
        {{ $t("userIsNotAllowedToOrder") }}
      </div>

      <b-card footer-class="border-0 bg-light">
        <b-row>
          <b-col class="d-flex flex-column">
            <label class="mb-0">{{ $t("orderNumber") }}</label>
            <small class="text-muted">{{ $t("optional") }}</small>
          </b-col>
          <b-col>
            <b-input v-model="reference"></b-input>
          </b-col>
        </b-row>

        <template #footer>
          <div class="d-flex justify-content-end align-items-center">
            <generate-offer-component
              :order="order"
              :products="products"
              :isCalculating="isCalculating"></generate-offer-component>

            <submit-button-component
              v-on:click="createOrder()"
              :state="submitButtonState"
              :label="$t('orderNow')"
              :block="false"
              class="my-2 ml-3"
              v-if="allowedToOrder"></submit-button-component>
          </div>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import moment from "moment-timezone";
  import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
  import GenerateOfferComponent from "../components/GenerateOfferComponent.vue";
  import {functions, firestore} from "../plugins/firebase";
  import {httpsCallable} from "firebase/functions";
  import {doc, getDoc, updateDoc, collection, getDocs, query} from "firebase/firestore";
  import {getPrices} from "../plugins/customModules/getPrices.js";
  import {getProductsFromServer} from "../plugins/customModules/getProducts.js";

  export default {
    components: {SubmitButtonComponent, GenerateOfferComponent},
    name: "CreateOrderCheckoutView",
    computed: {
      ...mapState(["settings"]),
      daysUntilCotermDate() {
        if (this.order.customer.cotermDate === "" || this.order?.isRenewal) {
          return this.daysInYear;
        } else {
          const referenceDate = moment.tz("America/Los_Angeles").startOf("day");
          const cotermDate = moment.tz(this.order.customer.cotermDate, "America/Los_Angeles").endOf("day");
          return cotermDate.diff(referenceDate, "days");
        }
      },
      daysInYear() {
        if (this.order.customer.cotermDate === "" || this.order?.isRenewal) {
          const referenceDate = moment.tz("America/Los_Angeles");
          return moment(referenceDate).add(1, "year").diff(referenceDate, "days");
        } else {
          const referenceDate = moment.tz(this.order.customer.cotermDate, "America/Los_Angeles").subtract(1, "year");
          // console.log(moment.tz(this.order.customer.cotermDate, "America/Los_Angeles").diff(referenceDate, "days"))
          return moment.tz(this.order.customer.cotermDate, "America/Los_Angeles").diff(referenceDate, "days");
        }
      },
      submitButtonState() {
        if (this.isWorking) {
          return "working";
        } else if (this.isCalculating) {
          return "missingFields";
        } else if (this.isBlocked) {
          return "blocked";
        }

        return "";
      },
      allowedToOrder() {
        return (
          this.$store.state.resellerSubUserData === null ||
          this.$store.state.resellerSubUserData.allowedToOrder === undefined ||
          this.$store.state.resellerSubUserData.allowedToOrder
        );
      },
    },
    data() {
      return {
        discountCode: "",
        timeNowString: null,
        timeNow: null,
        startTime: null,
        reference: "",
        isCalculating: true,
        isWorking: false,
        isBlocked: false,
        sum: 0,
        order: null,
        docId: "",
        products: [],
        allProducts: [],
        hvdPrices: [],
        customer: "",
        referenceDate: "",
        fields: [
          {
            key: "productName",
            label: "Produkt",
            sortable: true,
          },
          {
            key: "price",
            label: "Tagespreis",
            sortable: true,
          },
          {
            key: "cotermDatePrice",
            label: "Preis",
            sortable: true,
          },
        ],
        subscriptions: null,
      };
    },
    async mounted() {
      await this.getOrder();
      if (this.order.isRenewal) {
        this.referenceDate = this.order.customer.cotermDate;
        let splitedDate = this.order.customer.cotermDate.split("-");
        this.order.customer.cotermDate = Number(splitedDate[0]) + 1 + "-" + splitedDate[1] + "-" + splitedDate[2];
        // console.log(this.order.customer);
        this.discoundCode = this.order.customer?.discounts.find(
          (discount) => discount.offerType === "LICENSE"
        )?.discountCode;
      }
      this.checkBrowserTime();
      setInterval(() => {
        this.updateTimeNow();
      }, 1000);
      this.startTime = moment.tz("America/Los_Angeles");
      await this.updateOrder();
    },
    methods: {
      async updateTimeNow() {
        this.timeNowString = moment.tz("America/Los_Angeles").format("LL LTS");
        this.timeNow = moment.tz("America/Los_Angeles");
        const sameDay = moment(this.timeNow).isSame(this.startTime, "day");
        if (!sameDay) {
          // console.log("DayChange")
          this.startTime = moment.tz("America/Los_Angeles");
          this.updateOrder();
        }
      },
      async updateOrder() {
        this.isCalculating = true;

        const [
          blockedProducts,
          hiddenRenewalOfferProducts,
          hiddenProducts,
          minimumQuantities,
          marketSegments,
          hvdPrices,
        ] = await this.fetchAdditionalData();

        this.blockedProducts = blockedProducts;
        this.hiddenRenewalOfferProducts = hiddenRenewalOfferProducts;
        this.hiddenProducts = hiddenProducts;
        this.minimumQuantities = minimumQuantities;
        this.marketSegments = marketSegments;
        this.hvdPrices = hvdPrices;

        await this.getProducts();
        await this.updatePricesInOrder();
        this.products = [];
        await this.getOrder();
        this.isCalculating = false;
      },
      async updatePricesInOrder() {
        if (this.order == {}) return;
        try {
          const orderId = this.$route.params.orderId;
          const orderProductsRef = query(collection(firestore, `orders/${orderId}/products`));
          const productsOfOrder = await getDocs(orderProductsRef);

          if (!productsOfOrder.empty) {
            console.log(this.allProducts);
            productsOfOrder.forEach(async (document) => {
              const dataOfEl = document.data();
              // console.log(dataOfEl);
              const found = this.allProducts.find((element) => element.productName == dataOfEl.productName);
              // console.log(found);
              if (found) {
                const productRef = doc(firestore, `orders/${orderId}/products`, document.id);
                updateDoc(productRef, found);
              }
            });
          }
        } catch (error) {
          console.error(error);
          this.$store.dispatch("alertError", {
            message: "Es ist ein Fehler beim aktualisieren der Preise aufgetreten. Bitte versuchen Sie es erneut.",
            show: true,
            noFooter: false,
          });
        }
      },
      findPriceToUse(product) {
        let price = product.prices.find((price) => price.usePrice);

        if (price) {
          if (this.order?.isRenewal) {
            price.price = price.pricePerYear;
            price.endcustomerSalesPrice = price.endcustomerSalesPricePerYear;
            price.distributorPrice = price.distributorPricePerYear;
            price.imputedCost = price.imputedCostPerYear;
          }
          return price;
        } else {
          price = product.prices[0];
          if (this.order?.isRenewal) {
            price.price = price.pricePerYear;
            price.endcustomerSalesPrice = price.endcustomerSalesPricePerYear;
            price.distributorPrice = price.distributorPricePerYear;
            price.imputedCost = price.imputedCostPerYear;
          }
          return price;
        }
      },
      removeVolumeLevelFromOfferId(offerId) {
        if (!offerId) {
          return;
        }
        return offerId.replace(/CA\d\d/, "CA00").replace(/CAT\d/, "CAT0");
      },
      calcUnusedQuantity(product) {
        if (this.order?.isRenewal) return 0;
        const price = this.findPriceToUse(product);
        const subscription = this.subscriptions.find(
          (subscription) =>
            subscription?.offerId &&
            this.removeVolumeLevelFromOfferId(subscription?.offerId) ===
              this.removeVolumeLevelFromOfferId(price?.offerId)
        );

        let unusedQuantity = 0;

        if (subscription && subscription.currentQuantity && subscription.usedQuantity) {
          unusedQuantity = subscription.currentQuantity - subscription.usedQuantity;
        }

        return unusedQuantity;
      },
      async checkBrowserTime() {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const checkTime = httpsCallable(functions, "serverTime");
        const checkTimeState = await checkTime({
          timeZone: clientTimezone,
          time: moment().tz("Europe/Berlin").format("YYYY-MM-DD"),
        });
        if (!checkTimeState.data) {
          this.isBlocked = true;
          this.$store.dispatch("alertError", {
            message: "Es ist ein Fehler aufgetreten. Sie können Ihre Bestellung nicht abschließen.",
            show: true,
            noFooter: false,
          });
        }
      },
      async getOrder() {
        try {
          const docRef = doc(firestore, "orders", this.$route.params.orderId);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            this.docId = docSnapshot.id;
            this.order = docSnapshot.data();
            this.getSubscriptions(this.order.customer.customerId);
            this.getOrderItems();
          } else {
            this.$store.dispatch("alertError", {
              message: "Der Auftrag konnte nicht abgerufen werden.",
              show: true,
              noFooter: false,
            });

            this.$router.replace({name: "CreateOrderSelectCustomerView"});
          }
        } catch (error) {
          console.error(error);
          this.$store.dispatch("alertError", {
            message: "Der Auftrag konnte nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });

          this.$router.replace({name: "CreateOrderSelectCustomerView"});
        }
      },
      async getOrderItems() {
        const orderProductsColRef = collection(firestore, "orders", this.$route.params.orderId, "products");
        const orderProducts = await getDocs(orderProductsColRef);

        if (orderProducts.empty) {
          this.$store.dispatch("alertError", {
            message: "Es wurden keine Produkte oder Preise gefunden.",
            show: true,
            noFooter: false,
          });

          this.$router.replace({name: "CreateOrderSelectCustomerView"});
        }

        orderProducts.forEach((orderProduct) => {
          this.products.push(orderProduct.data());
        });

        if (!this.order?.isRenewal) {
          this.previewOrder();
        } else {
          this.previewRemnewalOrder();
        }
      },
      previewOrder() {
        const previewOrder = httpsCallable(functions, "getCustomerVolumeLevel");
        previewOrder({
          docId: this.docId,
          customerId: this.order.customer.customerId,
          order: {
            externalReferenceId: this.$route.params.orderId,
            currencyCode: "EUR",
            orderType: "PREVIEW",
            lineItems: this.generatePreviewLineItemsObject(this.products),
          },
        })
          .then((response) => {
            // console.log("response", response);
            this.products.map((product, index) => {
              const foundInResponse = response.data.lineItems.find((line) => line.extLineItemNumber == index + 1);
              const previewOfferId = foundInResponse.offerId;
              if (foundInResponse.discountCode) product.discountCode = foundInResponse.discountCode;
              product.prices = product.prices.map((price) => {
                if (price.offerId === previewOfferId) {
                  price.usePrice = true;
                } else {
                  price.usePrice = false;
                }
                return price;
              });
            });

            // this.isCalculating = false;
          })
          .catch((error) => {
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            });
          });
      },
      previewRemnewalOrder() {
        // console.log({
        //   orderType : "PREVIEW_RENEWAL",
        //   lineItems: this.generatePreviewLineItemsObject(this.products),
        // })
        const previewRemnewalOrder = httpsCallable(functions, "getCustomerVolumeLevel");
        previewRemnewalOrder({
          docId: this.docId,
          customerId: this.order.customer.customerId,
          order: {
            currencyCode: "EUR",
            orderType: "PREVIEW",
            lineItems: this.generatePreviewLineItemsObject(this.products),
          },
        })
          .then((response) => {
            this.products.map((product, index) => {
              const foundInResponse = response.data.lineItems.find((line) => line.extLineItemNumber == index + 1);
              const previewOfferId = foundInResponse.offerId;

              product.prices = product.prices.map((price) => {
                if (price.offerId === previewOfferId) {
                  price.usePrice = true;
                } else {
                  price.usePrice = false;
                }
                return price;
              });
            });

            // this.isCalculating = false;
          })
          .catch((error) => {
            // console.log(error)
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            });
          });
      },
      generatePreviewLineItemsObject(products) {
        return products.map((product, index) => {
          // console.log(product)
          const toReturn = {
            extLineItemNumber: index + 1,
            offerId: this.findPriceToUse(product).offerId || product.manufacturerItemNumber,
            quantity: product.quantity,
            subscriptionId: product?.subscriptionId,
          };

          // Hier!!!
          const discoundCode = this.order.customer?.discounts.find(
            (discount) => discount.offerType === "LICENSE"
          )?.discountCode;
          // console.log("discountCode", discoundCode);
          if (discoundCode && this.hvdPrices[this.findPriceToUse(product).offerId]) {
            toReturn.discountCode = discoundCode;
          }

          if (this.order?.isRenewal) {
            // delete toReturn.quantity
            // console.log(toReturn.subscriptionId)
          } else {
            delete toReturn.subscriptionId;
          }
          return toReturn;
        });
      },
      partnerOrderLines(products, customerId) {
        const orderLines = [];
        products.forEach((product) => {
          const kalkKostenTotal = product.quantity * this.findPriceToUse(product).distributorPrice || 0;
          const unitCost = this.findPriceToUse(product).distributorPrice;
          orderLines.push({
            kalk_kosten_total: parseFloat(kalkKostenTotal.toFixed(2)),
            vipmp_offer_id: this.findPriceToUse(product).offerId,
            name: product.productName,
            unitCost: unitCost,
            reference: `Customer-ID: ${customerId} // Jahrespreis pro Lizenz: ${
              this.findPriceToUse(product).distributorPricePerYear
            } // Tage im Jahr: ${this.daysInYear} // Anzahl Tage bis Anniversary Date: ${
              this.daysUntilCotermDate
            } // Anzahl der Lizenzen: ${product.quantity}`,
          });
        });
        return orderLines;
      },
      generateAdobeLineItemsObject(products) {
        const adobeLineItems = [];
        products.forEach((product, index) => {
          // console.log(product)
          let objectToPush = {
            extLineItemNumber: index + 1,
            offerId: this.findPriceToUse(product).offerId,
            quantity: product.quantity,
          };

          // Hier!!!
          const discoundCode = this.order.customer?.discounts.find(
            (discount) => discount.offerType === "LICENSE"
          )?.discountCode;
          // console.log("discountCode", discoundCode);
          if (discoundCode && this.hvdPrices[this.findPriceToUse(product).offerId]) {
            objectToPush.discountCode = discoundCode;
          }
          if (this.order.isRenewal) objectToPush.subscriptionId = product?.subscriptionId || "NEPE";
          adobeLineItems.push(objectToPush);
        });

        return adobeLineItems;
      },
      generateDeluxeLineItemsObject(products, customerId) {
        const deluxeLineItems = [];

        products.forEach((product) => {
          const kalkKostenTotal = product.quantity * this.findPriceToUse(product).imputedCost;
          const unitCost = this.findPriceToUse(product).price;
          // console.log("unitCost", unitCost);
          deluxeLineItems.push({
            sku: this.findPriceToUse(product).itemNumber,
            quantity: product.quantity,
            unit_cost: parseFloat(unitCost).toFixed(2),
            vipmp_offer_id: this.findPriceToUse(product).offerId,
            vipmp_subscription_id: "", // erhalte ich nach adobe order
            reference: `\n\nCustomer-ID: ${customerId}\nJahrespreis pro Lizenz: ${
              this.findPriceToUse(product).pricePerYear
            }\n Tage im Jahr: ${this.daysInYear} \nJahres-EVK pro Lizenz: ${
              this.findPriceToUse(product).endcustomerSalesPricePerYear
            }\nAnzahl Tage bis Anniversary Date: ${this.daysUntilCotermDate}\nAnzahl der Lizenzen: ${
              product.quantity
            } ${this.discountCode && "\nDiscountCode:" + this.discoundCode}\n\n`,
            kalk_kosten_total: parseFloat(kalkKostenTotal).toFixed(2),
            kalk_kosten_flag: true,
            valid_cost_difference: -1,
          });
        });

        return deluxeLineItems;
      },
      createOrder() {
        this.isWorking = true;

        if (this.reference == "") {
          this.reference = this.order.customer.resellerReferenceId;
        }

        const order = {
          sufficientLimit: false,
          sum: this.sum,
          docId: this.docId,
          customerId: this.order.customer.customerId,
          order: {
            externalReferenceId: this.$route.params.orderId,
            currencyCode: "EUR",
            orderType: this.order?.isRenewal ? "RENEWAL" : "NEW",
            lineItems: this.generateAdobeLineItemsObject(this.products),
          },
          deluxeOrder: {
            order_reference: this.order?.isRenewal ? `Renewal: ${this.reference || ""}` : `New: ${this.reference}`,
            customercode: this.$store.state.resellerData.customernumber,
            ship_to_adress1: this.order.customer.companyProfile.companyName,
            ship_to_street: this.order.customer.companyProfile.address.addressLine1,
            ship_to_street_extension: "",
            ship_to_plz: this.order.customer.companyProfile.address.postalCode,
            ship_to_city: this.order.customer.companyProfile.address.city,
            ship_to_country_iso: this.order.customer.companyProfile.address.country,
            type: "vipmp_order",
            vipmp_customer_id: this.order.customer.customerId,
            vipmp_order_id: "", // erhalte ich nach adobe order
            vipmp_endcustomer_email: this.order.customer.companyProfile.contacts[0].email,
            vipmp_endcustomer_phonenumber: this.order.customer.companyProfile.contacts[0].phoneNumber,
            vipmp_endcustomer_renewal_date: this.order.customer.cotermDate,
            order_lines: this.generateDeluxeLineItemsObject(this.products, this.order.customer.customerId),
          },
          partnerData: {
            resellerCustomerNumber: this.$store.state.resellerData.customernumber,
            order_lines: this.partnerOrderLines(this.products, this.order.customer.customerId),
          },
        };

        const removeFromAutoRenewalQueue = httpsCallable(functions, "removeFromAutoRenewalQueue");
        const createOrder = httpsCallable(functions, "createOrder");
        createOrder(order)
          .then(async () => {
            if (this.order?.isRenewal) {
              await removeFromAutoRenewalQueue(this.order.customer.customerId);
            }
            this.isWorking = false;
            this.$router.replace({name: "CreateOrderCompletedView"});
          })
          .catch((error) => {
            // console.error(error);
            this.isWorking = false;
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            });
          });
      },
      async getProducts() {
        // console.log("loading Products")
        const products = await getProductsFromServer(this.$store.state.resellerData.resellerId);

        if (products.empty) {
          this.$store.dispatch("alertError", {
            message: "Es wurden keine Produkte gefunden.",
            show: true,
            noFooter: false,
          });
          this.$router.replace({name: "CreateOrderSelectCustomerView"});
        }

        const requestProducts = [];

        products.forEach((productData) => {
          const itemNumberObject = this.$options.filters.itemNumberObject(productData.itemNumber);

          // Check if the volume level of the product is 1
          const isVolumeLevelOne = itemNumberObject.volumeLevel === 1;

          // Check if the product type is not 3YC
          const isNot3YC = !itemNumberObject.productType.match(/^[0-9]{2}3/g);

          // Checks if coterm date is within last seven days and a renewal from current year exists.
          const isWithinLastSevenDaysAndRenewalFromCurrentYearExists =
            this.cotermDateInLastSevenDays(this.order.customer.cotermDate) && this.renewalOrderFromCurrentYear;

          // Checks if the customer has no order in general.
          const customerHasNoOrders = this.customerHasNoOrders;

          // Checks if the customer's coterm date in the order is an empty string.
          const cotermDateIsEmpty = this.order.customer.cotermDate === "";

          // Checks if the customer's coterm date is in the future and within the current year.
          const cotermDateIsInFutureAndWithinCurrentYear =
            moment(this.order.customer.cotermDate).isAfter(moment()) &&
            moment(this.order.customer.cotermDate).isSame(moment(), "year");

          // Checks if the customer's coterm date is after the current year.
          const cotermDateIsAfterCurrentYear = moment(this.order.customer.cotermDate).isAfter(moment().format("YYYY"));

          // Check if the product is not included in the hidden products list.
          const isNotIncludedInHiddenProducts = !this.hiddenProducts.includes(
            `${itemNumberObject.productType}-${itemNumberObject.productCode}`
          );

          // Check if the product is not included in the hidden renewal offer products list
          const isNotIncludedInHiddenRenewalOfferProducts = !this.hiddenRenewalOfferProducts.includes(
            `${itemNumberObject.productType}-${itemNumberObject.productCode}`
          );

          // Check if the product type of the current item number object is included in the market segment product types
          const isIncludedInMarketSegments = this.marketSegments.includes(itemNumberObject.marketSegment);

          if (isVolumeLevelOne && isNot3YC && isIncludedInMarketSegments) {
            if (
              isWithinLastSevenDaysAndRenewalFromCurrentYearExists ||
              customerHasNoOrders ||
              cotermDateIsEmpty ||
              cotermDateIsInFutureAndWithinCurrentYear ||
              cotermDateIsAfterCurrentYear
            ) {
              // Is allowed to order promotional products
              if (isNotIncludedInHiddenProducts) {
                // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
                requestProducts.push(productData);
              }
            } else {
              // Is not allowed to order promotional products
              if (isNotIncludedInHiddenRenewalOfferProducts) {
                // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
                requestProducts.push(productData);
              }
            }
          }
        });

        const productsWithPrices = await getPrices(
          this.order.customer.customerId,
          {
            benefits: this.order.customer.benefits,
            cotermDate: this.order.customer.cotermDate,
            referenceDate:
              this.referenceDate != ""
                ? this.referenceDate
                : moment().tz("America/Los_Angeles").add(1, "days").format("YYYY-MM-DD"),
            discounts: this.order.customer.discounts,
            products: requestProducts,
            resellerId: this.order.customer.resellerId,
            marketSegment: this.order.customer.companyProfile.marketSegment,
          },
          true
        );

        this.allProducts = productsWithPrices.data;

        this.isWorking = false;
      },
      async fetchAdditionalData() {
        const promises = [
          this.fetchBlockedProducts(),
          this.fetchHiddenRenewalOfferProducts(),
          this.fetchHiddenProducts(this.order.customer.companyProfile.marketSegment),
          this.fetchMinimumQuantities(this.order.customer.companyProfile.marketSegment),
          this.fetchMarketSegmentProductTypes(this.order.customer.companyProfile.marketSegment),
          this.fetchHvdPrices(this.order.customer.customerId),
          this.fetchCustomerSubscriptions(this.order.customer.customerId),
          this.fetchOrderHistory(this.order.customer.customerId),
        ];

        return Promise.all(promises);
      },
      async fetchHvdPrices(customerId) {
        const docRef = doc(firestore, "hvdPrices", customerId);
        const snap = await getDoc(docRef);
        return snap.data()?.prices || [];
      },
      async fetchBlockedProducts() {
        const colRef = doc(firestore, "generalSettings", "blockedProducts");
        const snap = await getDoc(colRef);
        return snap.data().offerIds || [];
      },
      async fetchHiddenRenewalOfferProducts() {
        const colRef = doc(firestore, "generalSettings", "hiddenRenewalOfferProducts");
        const snap = await getDoc(colRef);
        return snap.data().productCodes || [];
      },
      async fetchHiddenProducts(marketSegment) {
        const colRef = doc(firestore, "generalSettings", "hiddenProducts");
        const snap = await getDoc(colRef);
        return snap.data()[marketSegment] || [];
      },
      async fetchMinimumQuantities(marketSegment) {
        const colRef = doc(firestore, "generalSettings", "minimumQuantities");
        const snap = await getDoc(colRef);
        return snap.data()[marketSegment] || {};
      },
      async fetchMarketSegmentProductTypes(marketSegment) {
        const colRef = doc(firestore, "generalSettings", "marketSegments");
        const snap = await getDoc(colRef);
        return snap.data()[marketSegment] || null;
      },
      async fetchCustomerSubscriptions(customerId) {
        const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
        const result = await getCustomerSubscriptions({
          customerId: customerId,
        });
        return result.data.items || [];
      },
      async fetchOrderHistory(customerId) {
        const getCustomerOrderHistory = httpsCallable(functions, "getCustomerOrderHistory");

        const result = await getCustomerOrderHistory({
          customerId: customerId,
        });

        return result.data.items;
      },
      cotermDateInLastSevenDays(cotermDate) {
        const daysBetweenTodayAndCotermDate = moment
          .tz("America/Los_Angeles")
          .diff(moment.tz(cotermDate, "America/Los_Angeles"), "days");

        return (
          (daysBetweenTodayAndCotermDate <= 7 && !daysBetweenTodayAndCotermDate < 0) || // Renewal is within 7 days (current year)
          (daysBetweenTodayAndCotermDate >= -365 && daysBetweenTodayAndCotermDate <= -358)
        ); // Renewal is within 7 days (next year)
      },
      calcShoppingCartSum(products) {
        let sum = 0;

        products.forEach(
          (product) => (sum += this.calcPositionSum(this.findPriceToUse(product).price, product.quantity))
        );

        this.sum = sum;

        return sum;
      },
      calcShoppingCartRrpSum(products) {
        let RrpSum = 0;

        products.forEach(
          (product) =>
            (RrpSum += this.calcPositionSum(this.findPriceToUse(product).endcustomerSalesPrice, product.quantity))
        );

        this.RrpSum = RrpSum;

        return RrpSum;
      },
      calcPositionSum(priceUntilCotermDate, quantity) {
        return priceUntilCotermDate * quantity;
      },
      formatCurrency(val, maximumFractionDigits) {
        return this.$options.filters.currency(val, maximumFractionDigits);
      },
      async getSubscriptions(customerId) {
        const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
        const subscriptions = await getCustomerSubscriptions({
          customerId: customerId,
        });
        this.subscriptions = subscriptions.data.items;
      },
      extractVolumeLevelFromOfferId(offerId) {
        if (offerId == undefined) return;

        return offerId.substring(offerId.length - 5, offerId.length - 3);
      },
      discountType(offerId) {
        return this.extractVolumeLevelFromOfferId(offerId).match(/T/g)
          ? this.$t("consumableLevel")
          : this.$t("volumeLevel");
      },
    },
  };
</script>
