<template>
<div>
  <div class="bg-light py-4 header-box">
    <b-container class="d-flex align-items-center">
      <b-button class="mr-3" variant="dark" :to="{ name: 'CustomersView' }"><fa-icon :icon="['far','angle-left']"></fa-icon></b-button>
      <h3 class="font-weight-bold mb-0">
        {{ $t("createCustomer") }}
      </h3>
    </b-container>
  </div>
  <b-container class="py-4">
    <p class="text-muted mb-4">
      <span v-html="$t('createCustomerInfoText')"></span> <router-link :to="{ name: 'TransferSubscriptionsView' }">{{ $t("here") }}</router-link>.
    </p>
    <b-form v-on:submit.prevent="submit">
      <b-card footer-class="border-0 bg-light">
        <h5 class="font-weight-bold">{{ $t("companyData") }}</h5>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("companyLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
            <small class="text-muted">{{ $t("afterwardsNotChangeableInfo") }}</small>
          </b-col>
          <b-col xl="5">
            <b-form-input
              id="input-companyname"
              type="text"
              v-model="companyProfile.companyName"
              :state="states.companyName"
              aria-describedby="input-companyname-feedback"
              trim
              required
            />
            <b-form-invalid-feedback id="input-companyname-feedback">
              {{ $t("companyInputErrorText") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <hr />
        
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("customerNumber") }}</label>
            <small class="text-muted">{{ $t("customerNumberIsNotRequired") }}</small>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="externalReferenceId"></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("addressLine1Label") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="companyProfile.address.addressLine1" required></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("addressLine2Label") }}</label>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="companyProfile.address.addressLine2"></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("postalCodeLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
          </b-col>
          <b-col xl="5">
            <b-form-input
              id="input-postalcode"
              type="text"
              v-model="companyProfile.address.postalCode"
              :state="states.postalCode"
              aria-describedby="input-postalcode-feedback"
              trim
              required
            />
            <b-form-invalid-feedback id="input-postalcode-feedback">
              {{ $t("postalCodeInputErrorText") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <hr />
        
        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("cityLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="companyProfile.address.city" required></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("countryLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
            <small class="text-muted">{{ $t("afterwardsNotChangeableInfo") }}</small>
          </b-col>
          <b-col xl="5">
            <country-dropdown-component
              :country="returnCountry"
              v-on:countrySelected="countrySelected"
            ></country-dropdown-component>
            <div>
              <b-button class="" variant="link" size="sm" @click="setDefaultCountry">Diese Auswahl als Standard setzen</b-button>
              <b-spinner small v-if="updatingDefaultCountry"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="companyProfile.address.phoneNumber"></b-form-input>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("prefferredLanguageLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
          </b-col>
          <b-col xl="5">
            <preferred-language-dropdown-component
              :preferredLanguage="returnPreferredLanguage"
              v-on:languageSelected="languageSelected"
            ></preferred-language-dropdown-component>
            <div>
              <b-button class="" variant="link" size="sm" @click="setDefaultPreferredLanguage">Diese Auswahl als Standard setzen</b-button>
              <b-spinner small v-if="updatingDefaultPreferredLanguage"></b-spinner>
            </div>
          </b-col>
        </b-row>
        
        <h5 class="mt-5 font-weight-bold">{{ $t("contactHeadline") }}</h5>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("firstNameLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
            <small class="text-muted">{{ $t("changeableInAdminConsoleInfo") }}</small>
          </b-col>
          <b-col xl="5">
            <b-form-input
              id="input-firstname"
              type="text"
              v-model="companyProfile.contacts[0].firstName"
              :state="states.firstName"
              aria-describedby="input-firstname-feedback"
              trim
              required
            />
            <b-form-invalid-feedback id="input-firstname-feedback">
              {{ $t("firstNameInputErrorText") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("lastNameLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
            <small class="text-muted">{{ $t("changeableInAdminConsoleInfo") }}</small>
          </b-col>
          <b-col xl="5">
            <b-form-input
              id="input-lastname"
              type="text"
              v-model="companyProfile.contacts[0].lastName"
              :state="states.lastName"
              aria-describedby="input-lastname-feedback"
              trim
              required
            />
            <b-form-invalid-feedback id="input-lastname-feedback">
              {{ $t("lastNameInputErrorText") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex align-items-center">
            <label class="mb-0">{{ $t("emailLabel") }}<span class="requied-field" v-b-tooltip.hover.top="this.$t('requied')">*</span></label>
          </b-col>
          <b-col xl="5">
            <b-form-input
              id="input-contact-email"
              type="email"
              v-model="companyProfile.contacts[0].email"
              :state="states.contactEmail"
              aria-describedby="input-contact-email-feedback"
              trim
              required
            />
            <b-form-invalid-feedback id="input-contact-email-feedback">
              {{ $t("emailInputErrorText") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
          </b-col>
          <b-col xl="5">
            <b-form-input v-model="companyProfile.contacts[0].phoneNumber"></b-form-input>
          </b-col>
        </b-row>

        <h5 class="mt-5 font-weight-bold">{{ $t("marketSegment") }}</h5>
        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-top">
            <small class="text-muted">{{ $t("changeableIfCustomerHasNoActiveSubscriptions") }}</small>
          </b-col>
          <b-col xl="5">
            <b-form-radio v-model="companyProfile.marketSegment" value="COM" v-if="checkIfResellerIsInMarketSegment('COM')">Commercial</b-form-radio>
            <b-form-radio v-model="companyProfile.marketSegment" value="EDU" v-if="checkIfResellerIsInMarketSegment('EDU')">Education</b-form-radio>
            <b-form-radio v-model="companyProfile.marketSegment" value="GOV" v-if="checkIfResellerIsInMarketSegment('GOV')">Government</b-form-radio>
          </b-col>
        </b-row>

        <span>
          <b-row>
            <b-col>
              <h5 class="mt-5 font-weight-bold" v-b-toggle="'collapse-et'">{{ $t("extendedTerm") }} <small class="text-muted">Optional</small></h5>
            </b-col>
            <b-col>
                <fa-icon v-b-toggle="'collapse-et'" class="text-right arrowPosition up" :icon="['fa-solid', 'angle-up']" style="height: 1.5em;"/>
                <fa-icon v-b-toggle="'collapse-et'" class="text-right arrowPosition down" :icon="['fa-solid', 'angle-down']" style="height: 1.5em;"/>
            </b-col>
          </b-row>          
          <hr />


          <b-collapse id="collapse-et" accordion="my-accordion">
            <p class="text-muted" v-html="$t('extendedTermDescription')"></p> 
            <!-- <hr /> -->

            <b-row>
              <b-col class="d-flex flex-column justify-content-center">
                <label class="mb-0">{{ $t("anniversaryDate") }}</label>
                <small class="text-muted">{{ $t("anniversaryDateCannotBeChanged") }}</small>
              </b-col>
              <b-col xl="5">
                <b-form-datepicker v-model="cotermDate" :min="minDate" :max="maxDate" :label-no-date-selected="$t('noAnniversaryDateSelected')" reset-button :disabled="isExtendedTermDisabled"></b-form-datepicker>
              </b-col>
            </b-row>
          </b-collapse>
        </span>

        <span>
          <b-row>
            <b-col>
              <h5 class="mt-5 font-weight-bold" v-b-toggle="'collapse-3yc'">{{ $t("threeyearcommit") }} <small class="text-muted">Optional</small></h5>
            </b-col>
            <b-col>
                <fa-icon v-b-toggle="'collapse-3yc'" class="text-right arrowPosition up" :icon="['fa-solid', 'angle-up']" style="height: 1.5em;"/>
                <fa-icon v-b-toggle="'collapse-3yc'" class="text-right arrowPosition down" :icon="['fa-solid', 'angle-down']" style="height: 1.5em;"/>
            </b-col>
          </b-row>
          <hr>

          <b-collapse id="collapse-3yc" accordion="my-accordion">
            <p class="text-muted" v-html="$t('threeyearcommitInfo')"></p>
            <!-- <hr /> -->

            <b-row>
              <b-col class="d-flex flex-column justify-content-center">
                <label class="mb-0">{{ $t("subscriptions") }}</label>
                <small class="text-muted">{{ $t("minimumAmountSubscriptions") }}</small>
              </b-col>
              <b-col xl="5">
                <b-form-input v-model="threeYC.licenses" type="number" min="0" @keypress="isNumber($event)" ref="licenses" :disabled="is3YCDisabled"></b-form-input>
                <small class="text-muted" v-show="(threeYC.licenses < 10 && threeYC.licenses != 0) && threeYC.licenses && threeYC.licenses != null">{{ $t("threeYearsCommitMinQuantity") }}</small>
              </b-col>
            </b-row>
            <hr />

            <b-row>
              <b-col class="d-flex flex-column justify-content-center">
                <label class="mb-0">{{ $t("consumables") }}</label>
                <small class="text-muted">{{ $t("minimumAmountConsumables") }}</small>
              </b-col>
              <b-col xl="5">
                <b-form-input v-model="threeYC.consumables" type="number" min="0" @keypress="isNumber($event)" ref="consumables" :disabled="is3YCDisabled"></b-form-input>
              </b-col>
            </b-row>

          </b-collapse>
        </span>

        <template #footer>
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-center align-items-center">
              <p class="text-muted" >* {{ $t("requied") }}</p>
            </div>
            <submit-button-component :state="submitButtonState" :label="$t('create')" :block="false" class="my-2"></submit-button-component>
          </div>
        </template>
      </b-card>
    </b-form>
  </b-container>
</div>
</template>

<script>
import PreferredLanguageDropdownComponent from "../components/PreferredLanguageDropdownComponent.vue";
import CountryDropdownComponent from "../components/CountryDropdownComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { mapState } from "vuex";
import moment from "moment-timezone";
import { doc, updateDoc } from "firebase/firestore";

export default {
  name: "CreateCustomerView",
  components: {
    PreferredLanguageDropdownComponent,
    CountryDropdownComponent,
    SubmitButtonComponent,
  },
  data() {
    return {
      updatingDefaultCountry: false,
      updatingDefaultPreferredLanguage: false,
      externalReferenceId: "",
      resellerId: this.$store.state.resellerData.resellerId,
      cotermDate: "",
      isWorking: false,
      companyProfile: {
        companyName: "",
        preferredLanguage: "de-DE",
        marketSegment: "COM",
        address: {
          addressLine1: "",
          addressLine2: "",
          postalCode: "",
          city: "",
          region: "00",
          country: "DE",
          phoneNumber: "",
        },
        contacts: [
          {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          },
        ],
      },
      states: {
        companyName: null,
        postalCode: null,
        firstName: null,
        lastName: null,
        contactEmail: null,
      },
      threeYC: {
        licenses: null,
        consumables: null
      },
      options: {
        threeYC: {
          licenses: [
            {value: null, text: '-',},
            {value: 10, text: '10-49',},
            {value: 50, text: '50-99',},
            {value: 100, text: '100+',}
          ],
          consumables: [
            {value: null, text: '-'},
            {value: 1, text: '1-999'},
            {value: 1000, text: '1000-2499'},
            {value: 2500, text: '2500-4999'},
            {value: 5000, text: '5000-14999'},
            {value: 15000, text: '15000-49999'},
            {value: 50000, text: '50000+'}
          ],
        }
      }
    };
  },
  computed: {
    ...mapState(["settings"]),
    submitButtonState() {
      if (
        !this.states.postalCode ||
        !this.states.companyName ||
        !this.states.firstName ||
        !this.states.lastName ||
        !this.states.contactEmail ||
        this.companyProfile.address.addressLine1 === "" ||
        this.companyProfile.address.city === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
    minDate() {
      return moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD');
    },
    maxDate() {
      if (this.companyProfile.marketSegment === "COM") {
        return moment().add(3, 'years').format('YYYY-MM-DD');
      } else {
        return moment().add(4, 'years').format('YYYY-MM-DD');
      }
    },
    is3YCDisabled() {
      return this.cotermDate !== "";
    },
    isExtendedTermDisabled() {
      return (this.threeYC.licenses !== null && this.threeYC.licenses !== "") || (this.threeYC.consumables !== null && this.threeYC.consumables !== "")
    },
    returnCountry() {
      return this.$store.state.resellerData?.settings?.defaultCountry || this.companyProfile.address.country;
    },
    returnPreferredLanguage() {
      return this.$store.state.resellerData?.settings?.defaultPreferredLanguage || this.companyProfile.preferredLanguage;
    },
  },
  methods: {
    async setDefaultCountry() {
      this.updatingDefaultCountry = true;

      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.firestoreId);
      await updateDoc(docRef, {
        "settings.defaultCountry": this.companyProfile.address.country,
      });

      this.updatingDefaultCountry = false;
    },
    async setDefaultPreferredLanguage() {
      this.updatingDefaultPreferredLanguage = true;

      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.firestoreId);
      await updateDoc(docRef, {
        "settings.defaultPreferredLanguage": this.companyProfile.preferredLanguage,
      });

      this.updatingDefaultPreferredLanguage = false;
    },
    checkIfResellerIsInMarketSegment(marketSegment) {
      return this.$store.state.resellerData.companyProfile.marketSegments.includes(marketSegment);
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    languageSelected(preferredLanguage) {
      this.companyProfile.preferredLanguage = preferredLanguage;
    },
    countrySelected(country) {
      this.companyProfile.address.country = country;
    },
    async submit() {
      this.isWorking = true;

      const createCustomerAccount = httpsCallable(functions, "createCustomerAccount");
      await createCustomerAccount({
        resellerId: this.resellerId,
        cotermDate: this.cotermDate,
        companyProfile: this.companyProfile,
        threeYC: this.threeYC,
        externalReferenceId: this.externalReferenceId,
      })
        .then(() => {
          this.companyProfile.companyName = "";
          this.externalReferenceId = "";
          this.cotermDate = "";
          this.companyProfile.preferredLanguage = "de-DE";
          this.companyProfile.address.addressLine1 = "";
          this.companyProfile.address.addressLine2 = "";
          this.companyProfile.address.postalCode = "";
          this.companyProfile.address.city = "";
          this.companyProfile.address.country = this.settings?.defaultCountry || "DE";
          this.companyProfile.address.phoneNumber = "";
          this.companyProfile.contacts[0].firstName = "";
          this.companyProfile.contacts[0].lastName = "";
          this.companyProfile.contacts[0].email = "";
          this.companyProfile.contacts[0].phoneNumber = "";
          this.states.postalCode = null;
          this.states.companyName = null;
          this.states.firstName = null;
          this.states.lastName = null;
          this.states.contactEmail = null;

          this.$store.dispatch("alertSuccess", {
            message: "Der Kunde wurde erstellt.",
            show: true,
            noFooter: false,
          })
          this.$router.replace({ name: "CustomersView" });
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
      
      this.isWorking = false;
    },
  },
  watch: {
    "companyProfile.companyName": function(companyName) {
      if (companyName === "") {
        this.states.companyName = null;
      } else {
        if ( companyName.match(this.$store.state.companyNameRegex) ) {
          this.states.companyName = true;
        } else {
          this.states.companyName = false;
        }
      }
    },
    "companyProfile.address.country": function(country) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (country === "") {
        this.states.postalCode = null;
      } else {
        if ( this.companyProfile.address.postalCode.match(postalCodeRegex[country]) ) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
    "companyProfile.address.postalCode": function(postalCode) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (postalCode === "") {
        this.states.postalCode = null;
      } else {
        if ( postalCode.match(postalCodeRegex[this.companyProfile.address.country]) ) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
    "companyProfile.contacts": {
      deep: true,
      handler: function(contacts) {
        if (contacts[0].email === "") {
          this.states.contactEmail = null;
        } else {
          this.states.contactEmail = isEmail(contacts[0].email);
        }

        if (contacts[0].firstName === "") {
          this.states.firstName = null;
        } else {
          if ( contacts[0].firstName.match(this.$store.state.nameRegex) ) {
            this.states.firstName = true;
          } else {
            this.states.firstName = false;
          }
        }

        if (contacts[0].lastName === "") {
          this.states.lastName = null;
        } else {
          if ( contacts[0].lastName.match(this.$store.state.nameRegex) ) {
            this.states.lastName = true;
          } else {
            this.states.lastName = false;
          }
        }
      },
    },
    cotermDate(value) {
      if (value !== "") {
        this.threeYC.licenses = null;
        this.threeYC.consumables = null;
      }
    },
    "threeYC.licenses": {
      handler: function(value) {
        if (value !== null) {
          this.cotermDate = "";
        }
      },
    },
    "threeYC.consumables": {
      handler: function(value) {
        if (value !== null) {
          this.cotermDate = "";
        }
      },
    },
  },
  mounted() {
    const changeSelection =  this.settings?.defaultCountry || 'DE';
    this.companyProfile.address.country = changeSelection
  }
};
</script>

<style scoped>
.requied-field {
  color: #6c757d;
  vertical-align: super;
  font-size: 14px;
}
.arrowPosition {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 20px;
  margin: 0;
}
span:has(.collapse:not(.show)) .arrowPosition.up {
    display: none;
}
span:has(.collapse.show) .arrowPosition.down {
    display: none;
}
</style>