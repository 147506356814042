<template>
  <b-form v-on:submit.prevent="saveDiscount">
    <div class="mb-4 text-muted" v-html="$t('customerDiscountText')"></div>
    <b-form-group class="w-25">
      <b-input-group class="mt-3">
        <template #append>
            <b-input-group-text><strong class="text">%</strong></b-input-group-text>
        </template>
        <b-form-input
          id="input-discount"
          type="text"
          v-model="discount"
          :label="$t('discount')"
          trim
          required
          :state="validation"
        />
      </b-input-group>
      <b-form-invalid-feedback id="input-discount-feedback" :state="validation">
        {{ $t("discountErrorText") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" class="my-2"></submit-button-component>
  </b-form>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { firestore } from "../plugins/firebase";
import { doc, updateDoc, query, collection, getDocs, where, onSnapshot } from "firebase/firestore";

export default {
  name: "CustomerDiscountView",
  components: {
    SubmitButtonComponent
  },
  async mounted() {
    const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
    const querySnapshot = await getDocs(colRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        this.docId = doc.id;
      });

      if (this.docId !== null) {
        const docRef = doc(firestore, "customers", this.docId);
        onSnapshot(docRef, (doc) => {
          this.discount = doc.data()?.customerDiscount.toString().replace(".", ",") || 0;
          this.discountInit = doc.data()?.customerDiscount.toString().replace(".", ",") || 0;
        })
      }
    }
  },
  data() {
    return {
      discount: 0,
      discountInit: 0,
      isWorking: false,
      docId: null,
      states: {
        customerDiscount: null
      },
      validation: false,
    }
  },
  computed: {
    submitButtonState() {
      if (!this.validation) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    }
  },
  methods: {
    async saveDiscount() {
      const discount = Number(this.discount.toString().replace(",", "."))

      this.isWorking = true;

      const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          await updateDoc(docRef, {
            customerDiscount: discount || 0,
          });
        }
      }

      this.isWorking = false;
    },
  },
  watch: {
    discount: function(discount) {
      discount = Number(discount.toString().replace(",", "."))

      if (isNaN(discount) || discount < 0 || discount > 100) {
        this.validation = false;
      } else {
        this.validation = true;
      }
    },
  }
}
</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>