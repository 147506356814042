<template>
  <div class="pt-3">
    <b-form v-on:submit.prevent="saveNotice">
      <b-form-group class="w-100">
        <b-form-textarea
        id="input-notice"
        v-model="notice"
        debounce="500"
        @update="saveNotice()"
        :class="isWorking == 'saving' ? 'saving' : (isWorking == 'saved' ? 'saved' : '')"
        rows="1"
        max-rows="8"
        :placeholder="$t('customerNotices')"
      ></b-form-textarea>
      </b-form-group>
    </b-form>
   </div>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { doc, updateDoc, query, collection, getDocs, where, onSnapshot } from "firebase/firestore";

export default {
  name: "CustomerNotice",
  async mounted() {
    const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
    const querySnapshot = await getDocs(colRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        this.docId = doc.id;
      });

      if (this.docId !== null) {
        const docRef = doc(firestore, "customers", this.docId);
        onSnapshot(docRef, (doc) => {
          this.notice = doc.data().notice;
        });
      }
    }
  },
  data() {
    return {
      notice: "",
      isWorking: null,
      docId: null,
      states: {
        notice: null
      }
    }
  },
  methods: {
    async saveNotice() {
      this.notice;

      this.isWorking = "saving";

      const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          await updateDoc(docRef, {
            notice: this. notice,
          });
        }
      }

        this.isWorking = "saved";
        setTimeout(() => {
          this.isWorking = null;
        }, 1000);
    },
  },
}
</script>

<style>
#input-notice { 
  min-height: 45px !important;
}
textarea:focus {   
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0)  !important;
  outline: 0 none !important;
}
textarea:focus.saving,
textarea.saving {
  border-color: #767676;
  padding-right: calc(0.5em + 0.75rem) !important;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNy4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjUgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iIzc2NzY3NiIgZD0iTTY0IDMyQzI4LjcgMzIgMCA2MC43IDAgOTZMMCA0MTZjMCAzNS4zIDI4LjcgNjQgNjQgNjRsMzIwIDBjMzUuMyAwIDY0LTI4LjcgNjQtNjRsMC0yNDIuN2MwLTE3LTYuNy0zMy4zLTE4LjctNDUuM0wzNTIgNTAuN0MzNDAgMzguNyAzMjMuNyAzMiAzMDYuNyAzMkw2NCAzMnptMCA5NmMwLTE3LjcgMTQuMy0zMiAzMi0zMmwxOTIgMGMxNy43IDAgMzIgMTQuMyAzMiAzMmwwIDY0YzAgMTcuNy0xNC4zIDMyLTMyIDMyTDk2IDIyNGMtMTcuNyAwLTMyLTE0LjMtMzItMzJsMC02NHpNMjI0IDI4OGE2NCA2NCAwIDEgMSAwIDEyOCA2NCA2NCAwIDEgMSAwLTEyOHoiLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) top 10px;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: 0 0 0 0.2rem rgba(127, 134, 131, 0.25) !important;
}
textarea:focus.saved,
textarea.saved {
  border-color: #1c9b60;
  padding-right: calc(0.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) top 10px;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  box-shadow: 0 0 0 0.2rem rgba(28, 155, 96, 0.25) !important;
}
textarea { 
  transition: all 0.5s ease-in-out;
}
</style>